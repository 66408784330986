const API_URL = process.env.REACT_APP_API;

async function postBooking(axiosPrivate, body) {
    let result = await axiosPrivate.post(
        `${API_URL}/api/book?instance=1`,
        // `http://localhost:3009/api/book`,
        body,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    return result;
}

async function postDriverData(axiosPrivate, body) {
    let result = await axiosPrivate.post(
        `${API_URL}/api/??`,
        // `http://localhost:3009/api/book`,
        body,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    return result;
}

async function getCar(axiosPrivate, carId, search) {
    let result = await axiosPrivate.get(
        `${API_URL}/api/book/vehicles/${carId}${search}&instance=1`,
        // `http://localhost:3009/api/book/vehicles/${carId}${search}`,
        {}
    );

    return result;
}

async function getAllCars(axiosPrivate, search) {

    let axiosResults = await axiosPrivate.get(
        `${API_URL}/api/book/vehicles${search}&instance=1`,
        // `http://localhost:3009/api/book/vehicles${search}`,
        {}
    );
    // if (axiosResults.status == "200") {
    //     results = axiosResults;
    // }

    console.log("axiosResults: ", axiosResults)

    return axiosResults;
}

export {
    postBooking,
    getCar,
    getAllCars,
    postDriverData
}