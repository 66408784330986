import { useContext } from 'react';
import {
    Link,
    NavLink,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

import lang from "../lang/lang.json";

function Footer() {
    const params = useParams();

    return (<>
        <footer className="footer">
            <div className="container">
                <div className="row d-flex justify-content-between">
                    <div className="col-md-4">

                        <a href="/" className="d-flex align-items-center mb-0 text-white text-decoration-none">
                            <img src="../../../images/logo.webp" width="220" height="39" alt="Ola Mauritius company logo" />
                        </a>
                        <div className="footer-text">
                            <span>
                                {lang[params.lang]?.footer.info ||
                                    "OLA Mauritius Car Rental is a recognized car rental company specialized in low-budget and high-end car and scooter rental and airport transfer across the island."}
                            </span>
                        </div>
                        <div className="footer-media">
                            <a href="http://www.facebook.com/olamauritius" target="_blank" aria-label="facebook">
                                <i className="bi bi-facebook"></i>
                            </a>
                            <a href="http://www.twitter.com/olamauritius/" target="_blank" aria-label="twitter">
                                <i className="bi bi-twitter-x"></i>
                            </a>
                            <a href="https://www.instagram.com/olamauritius/" target="_blank" aria-label="instagram">
                                <i className="bi bi-instagram"></i>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-7">
                        <div className="row justify-content-center">

                            <div className="col-6 col-md-4">
                                <span className="footer-list-title pt-5 pt-md-0 bold">
                                    {lang[params.lang]?.footer.rentalServices || "Rental Services"}
                                </span>
                                <ul className="footer-list">
                                    <li className="footer-list-item">
                                        <a href={params.lang ? `/${params.lang}/` : `/en/`}>
                                            {lang[params.lang]?.footer.mauritiusCarRental || "Mauritius Car Rental"}
                                        </a>
                                    </li>
                                    <li className="footer-list-item">
                                        <a href={params.lang ? `/${params.lang}/services/airport-transfer` : `en/services/airport-transfer`}>
                                            {lang[params.lang]?.footer.airportTransfer || "Airport Transfer"}
                                        </a>
                                    </li>
                                </ul>


                                <div className="d-block d-md-none">
                                    <span className="footer-list-title pt-5 pt-md-0">{lang[params.lang]?.footer.services || "Services"}</span>
                                    <ul className="footer-list">
                                        <li className="footer-list-item">
                                            <a href={params.lang ? `/${params.lang}/contact-us` : `en/contact-us`}>
                                                {lang[params.lang]?.footer.contactUs || "Contact us"}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-6 col-md-4">
                                <span className="footer-list-title pt-5 pt-md-0 bold">{lang[params.lang]?.footer.support || "Support"}</span>
                                <ul className="footer-list">
                                    <li className="footer-list-item">
                                        <a href={params.lang ? `/${params.lang}/benefits` : `en/benefits`}>
                                            {lang[params.lang]?.footer.benefits || "Benefits"}
                                        </a>
                                    </li>
                                    <li className="footer-list-item">
                                        <a href={params.lang ? `/${params.lang}/why-choose-us` : `en/why-choose-us`}>
                                            {lang[params.lang]?.footer.whyOla || "Why Ola"}
                                        </a>
                                    </li>
                                    <li className="footer-list-item">
                                        <a href={params.lang ? `/${params.lang}/testimonials` : `en/testimonials`}>
                                            {lang[params.lang]?.footer.testimonials || "Testimonials"}
                                        </a>
                                    </li>
                                    <li className="footer-list-item">
                                        <a href={params.lang ? `/${params.lang}/why-choose-us/faq` : `en/why-choose-us/faq`}>
                                            {lang[params.lang]?.footer.help || "Help/FAQ"}
                                        </a>
                                    </li>
                                    <li className="footer-list-item">
                                        <a href={params.lang ? `/${params.lang}/blogs` : `/en/blogs`}>
                                            {lang[params.lang]?.footer.blog || "Blog"}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-4">
                                <div className="d-none d-md-block">
                                    <span className="footer-list-title pt-5 pt-md-0 bold">{lang[params.lang]?.footer.services || "Services"}</span>
                                    <ul className="footer-list">
                                        <li className="footer-list-item">
                                            <a href={params.lang ? `/${params.lang}/contact-us` : `en/contact-us`}>
                                                {lang[params.lang]?.footer.contactUs || "Contact us"}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
            <div className="footer-bottom py-3">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-6 text-center text-sm-start footer-bottom-rights">
                            {lang[params.lang]?.footer.rights ||
                                "© 2024 OLA Mauritius All rights reserved."}
                        </div>

                        <div className="col-12 col-md-6">
                            <ul className="footer-list m-0 m-0 d-block text-center d-sm-flex gap-5 justify-content-end">
                                <li className="footer-list-item">
                                    <a href={params.lang ? `/${params.lang}/privacy-policy` : `en/privacy-policy`}>
                                        {lang[params.lang]?.footer.privacy || "Privacy"}
                                    </a>
                                </li>
                                <li className="footer-list-item">
                                    <a href={params.lang ? `/${params.lang}/terms-and-conditions` : `en/terms-and-conditions`}>
                                        {lang[params.lang]?.footer.terms || "Terms"}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    </>
    );
}

export default Footer;