import React, { useState, useEffect } from 'react';
import {
    Link,
    useParams,
    useLocation,
    useSearchParams,
    useOutletContext
} from "react-router-dom";
import { Helmet } from "react-helmet";

import { postDriverData } from '../../services/apiCarsService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "react-phone-input-2/lib/bootstrap.css";

function DriverProfile() {
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    console.log(searchParams.get('fullname'));
    const [submitted, setSubmitted] = useState(false)
    const [formData, setFormData] = useState({
        fullName: searchParams.get('fullname') ?? '',
        email: searchParams.get('email') ?? '',
        passportNumber: '',
        driverLicenseNumber: '',
    })

    const onChangeHandler = (e) => {
        const stateIdentifier = e.target.getAttribute('name')
        setFormData(prevState => {
            return {
                ...prevState,
                [stateIdentifier]:e.target.value
            }
        })
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        const body = {
            fullName:e.target.fullName.value,
            email: e.target.email.value,
            passportNumber: e.target.passportNumber.value,
            driverLicenseNumber: e.target.driverLicenseNumber.value,
            passportFile: e.target.passportFile.value,
            driverLicenseFile: e.target.driverLicenseFile.value
        }

        const  responce = await postDriverData(axiosPrivate, body);
        setSubmitted(true)        
    }


    return (<>

        <Helmet>
            <title>Choose your vehicle</title>
            <meta name="description" content="OLA Mauritius Car Rental is an established car hire company that offers modern rental cars at a reasonable price, with Free GPS and Transfer." />
            <meta name="keywords" content="Rent a car, Car Rental, Hire Car" />
        </Helmet>


        
        <div className="bg-gray pb-5">
            {
                <div className="results-container container-xxl pb-5">

                    <h2 className="py-2 pt-5 mt-3 mb-0 bold">
                        Driver Profile Form
                    </h2>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card card__border p-3 my-4">
                                <div className="card-body">
                                    <h6 className="cars-header-title bold pb-2">
                                    Benefits
                                    </h6>
                                    <div className="semi-bold">
                                        <div className="my-2">
                                            <i className="bi bi-check-lg text-green"></i> A-Class Fleet
                                        </div>
                                        <div className="my-2">
                                            <i className="bi bi-check-lg text-green"></i> Free Additional Driver
                                        </div>
                                        <div className="mt-2">
                                            <i className="bi bi-check-lg text-green"></i> Free Child Seats
                                        </div>
                                        <div className="mt-2">
                                            <i className="bi bi-check-lg text-green"></i> All Comprehensive Insurance Cover
                                        </div>
                                        <div className="mt-2">
                                            <i className="bi bi-check-lg text-green"></i> 24 hours Roadside Assistance
                                        </div>
                                        <div className="mt-2">
                                            <i className="bi bi-check-lg text-green"></i> Free Delivery & Pick up
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card card__border p-3 my-4">
                                <div className="card-body">
                                    <div className="card-body d-flex flex-column">
                                        <h6 className="bold pb-3 mb-3 border-bottom">
                                            Guaranteed Low Price
                                        </h6>
                                        <p className='mb-0'>PRICE START AT</p>
                                        <p className='driver-profile__price--container ms-0 ps-0 mb-0'>
                                            <span className='driver-profile__currency'>€</span>
                                            <span className='driver-profile__price bold'>25</span>
                                            <span className='driver-profile__terms'>/day</span>
                                        </p>
                                        <div className='driver-profile__img d-flex justify-content-center'><img src="http://www.olamauritius.com/olares/uploader/images/kia-picanto-1481091390.jpg"/></div>
                                        <Link className='btn btn-outline-dark mt-3 driver-profile__home-link' to='/'>SEARCH FOR CAR</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-8">

                            <form id="submitCheckout" onSubmit={onSubmitHandler}>
                                <div className="card card__border p-3 my-4">
                                    <div className="card-body">
                                        <h5 className="bold pb-2">
                                            Reservation Number
                                        </h5>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="orderId" className="bold mt-3 mb-2">Reservation Number</label>
                                                <input type="text" name="orderId" className="input__checkout w-100" id='orderId'
                                                    value={searchParams.get('id')}
                                                    disabled
                                                ></input>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="card card__border p-3 my-4">
                                    <div className="card-body">
                                        <h5 className="bold pb-2">
                                            Driver's Contact
                                        </h5>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="fullName" className="bold mt-3 mb-2">Full Name*</label>
                                                <input type="text" name="fullName" className="input__checkout w-100" id='fullName'
                                                    value={formData.fullName} onChange={onChangeHandler}
                                                    required
                                                ></input>
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="email" className="bold mt-3 mb-2">Email Address*</label>
                                                <input type="email" name="email" className="input__checkout w-100" id='email'
                                                    value={formData.email} onChange={onChangeHandler}
                                                    required
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card__border p-3 my-4">
                                    <div className="card-body">
                                        <h5 className="bold pb-2">
                                            Document Upload
                                        </h5>
                                        <p>
                                            Please upload an optimized high resolution scan of your passport and driving license document
                                        </p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="passportNumber" className="bold mt-3 mb-2">Passport Number</label>
                                                <input type="number" name="passportNumber" className="input__checkout w-100" id='passportNumber'
                                                    value={formData.passportNumber} onChange={onChangeHandler}
                                                    required
                                                ></input>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="passportFile" className="bold mt-3 mb-2">Passport Upload</label>
                                                <input type="file" name="passportFile" id='passportFile' className="driver-profile__input--file input__checkout w-100 input--number-as-text"
                                                    onChange={(e) => {}}
                                                    required
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="driverLicenseNumber" className="bold mt-3 mb-2">Driving License Number</label>
                                                <input type="number" name="driverLicenseNumber" className="input__checkout w-100" id='driverLicenseNumber'
                                                    value={formData.driverLicenseNumber} onChange={onChangeHandler}
                                                    required
                                                ></input>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="driverLicenseFile" className="bold mt-3 mb-2">Driving License Upload</label>
                                                <input type="file" name="driverLicenseFile" id='driverLicenseFile' className="driver-profile__input--file input__checkout w-100 input--number-as-text"
                                                    onChange={(e) => {}}
                                                    required
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-3 ms-auto'>
                                        <button className='mt-0 btn btn-primary w-100 change__color--btn' disabled={submitted}>{submitted ? 'Done' : 'Submit'}</button>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </div>
    </>);
}

export default DriverProfile;