import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import lang from "../lang/lang.json";

function Benefits() {
	const params = useParams();

	return (<div className="ola-old-pages">
		<section className="internal-header">

			<div className="container">
				<div className="row v-align-children">
					<div className="col-md-8 bold">
						<h1>
							{lang[params.lang]?.footer.benefits || "Benefits"}
						</h1>
					</div>

					<div className="col-md-4 d-none d-md-block">
						<ol className="breadcrumb float-end semi-bold">
							<li>
								<a href={params.lang ? `/${params.lang}/` : `en/`}>
									{
										params.lang == "fr" ?
											"Accueil"
											:
											"Home"
									}
								</a>
							</li>
							<li className="current">
								{lang[params.lang]?.footer.benefits || "Benefits"}
							</li>
						</ol>
					</div>

				</div>
			</div>
		</section>

		<div className="container mt-2 mb-5">
			{
				params.lang == "fr" ?
					<div className="row">
						<div className="col-md-8">
							<section className="text-box">
								<article>
									<p>Choisissez OLA Mauritius comme votre agence de location de voitures et bénéficiez de nos avantages exclusifs et profitez aussi pleinement de l’île Maurice à travers une expérience unique de location de voiture.  Voici les avantages dont vous bénéficierez en louant chez nous :</p>
									<div id="benefits">
										<p><a name="fleet"></a></p>
										<h3 className="text-secondary"><span className="accent">1.</span>Des voitures haut de gamme</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-fleet.webp" alt="fleet" width="136" height="58" />
											Nos voitures sont récentes et en très bon état. Nous adhérons aux normes internationales et de par notre engagement en termes de qualité vous êtes sûr de disposer d’excellents véhicules bien entretenus et propres.
											<br />
											<a name="gps"></a></p>
										<h3 className="text-secondary"><span className="accent">2.</span>Un système de navigation GPS à un prix minimale</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-gprs.webp" alt="gps" width="136" height="58" />
											Toutes nos voitures sont équipées d’un système de navigation GPS (selon disponibilité) vous permettant de conduire l’esprit tranquille à l’île Maurice.  Nous mettons à votre disposition des GPS TomTom et Garmin.
											<a name="driver"></a></p>
										<h3 className="text-secondary"><span className="accent">3.</span>Un chauffeur supplémentaire gratuit</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-additional-driver.webp" alt="additional-driver" width="136" height="58" />
											Nous sommes très flexibles et proposons une option avec conducteur supplémentaire gratuit, cette option est incluse dans votre forfait de location. Il vous suffit de nous fournir  les informations nécessaires du deuxième conducteur lorsque vous prendrez la voiture.
											<a name="child"></a></p>
										<h3 className="text-secondary"><span className="accent">4.</span>Sièges auto pour enfants</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-car-seat.webp" alt="car-seat" width="136" height="58" />
											Votre sécurité, y compris celle de votre enfant, est quelque chose dont nous nous soucions énormément. Toutes nos voitures sont équipées avec un siège pour enfant a un prix compétitif .Il suffit de le préciser lors de la réservation en ligne.
											<a name="insurance"></a></p>
										<h3 className="text-secondary"><span className="accent">5.</span>Une assurance tous risques</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-insurance.webp" alt="insurance" width="136" height="58" />
											Louer un véhicule avec OLA Mauritius c’est aussi bénéficier d’une assurance tous risques incluse dans votre forfait de location. Vous limitez donc votre responsabilité financière en cas d’accident.
											<a name="road"></a></p>
										<h3 className="text-secondary"><span className="accent">6.</span> Une assistance routière de 24 heures</h3>
										<hr />
										<p><img className="float-end" src="../../images/pages/benefit-assistance.webp" alt="assistance" width="136" height="58" />
											Nous offrons, en même temps que la voiture, une assistance routière 24/7 en cas de panne mécanique. Notre équipe vous aidera à reprendre la route même si cela nécessite le remplacement de la voiture par une autre.
											<a name="delivery"></a></p>
										<h3 className="text-secondary"><span className="accent">7.</span>Livraison et récupération du véhicule gratuite</h3>
										<hr />
										<p><img className="float-end" src="../../images/pages/benefit-keys.webp" alt="keys" width="136" height="58" />
											Nous offrons la possibilité d’obtenir  et de rendre votre voiture  à  l’aéroport, votre hôtel ou villa ou autres emplacements,selon votre convenance.  Les déplacements à l’aéroport sont toujours gratuits,ceux à l’hôtel sont gratuits pour les locations de plus de 5 jours.</p>
									</div>
								</article>



							</section>
						</div>

						<div className="sidebar col-md-4">
							<aside className="sidebar-widget">
								<h4 className="widget-title semi-bold">Vos Plus</h4>
								<hr />
								<div className="textwidget semi-bold">
									<div><i className="bi bi-check"></i>Des voitures haut de gamme</div>
									<div><i className="bi bi-check"></i>Un système de navigation GPS à un prix minimale</div>
									<div><i className="bi bi-check"></i>Un chauffeur supplémentaire gratuit</div>
									<div><i className="bi bi-check"></i>Sièges auto pour enfants</div>
									<div><i className="bi bi-check"></i>Une assurance tous risques</div>
									<div><i className="bi bi-check"></i>Une assistance routière de 24 heures</div>
									<div><i className="bi bi-check"></i>Livraison et récupération du véhicule gratuite</div>
								</div>
							</aside>
							<aside className="sidebar-widget">
								<h4 className="widget-title mt-4">Prix ​​bas garanti</h4>
								<hr />
								<div className="textwidget">
									<div className="pricing-option active ">
										<strong>Le prix commence à</strong>
										<div className="price-container text-center">
											<span className="dollar text-secondary">€</span>
											<span className="price text-secondary">25</span>
											<span className="terms text-secondary">/jour</span>
										</div>
										<img className="d-block m-auto" src="../../images/pages/homecar.webp" alt="car" width="213" height="104" />

										<a href="/fr" className="btn btn-primary fw-bold w-100 text-white my-4">
											Rechercher une voiture
										</a>

									</div></div>
							</aside>

						</div>

					</div>
					:
					<div className="row">
						<div className="col-md-8">
							<section className="text-box">
								<article>
									<p>Choosing OLA Mauritius as your car rental partner means you will <strong>benefit from our exclusive benefits</strong> and thus enhancing your discovery of Mauritius through your <strong>unique car hiring experience</strong>. Here is what you get by renting with us:</p>
									<div id="benefits">
										<p><a name="fleet"></a></p>
										<h3 className="text-secondary"><span className="accent">1.</span>A-Class Fleet</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-fleet.webp" alt="fleet" width="136" height="58" />
											Our cars are spotlessly clean and in excellent roadworthy condition. We stick to international standards and will never compromise with quality. We are simply obsessed to excellence!
											<br />
											<a name="gps"></a></p>
										<h3 className="text-secondary"><span className="accent">2.</span>GPS</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-gprs.webp" alt="gps" width="136" height="58" />
											We offer Navigation system at minimum rates ( subject to availability)&nbsp; allowing you to easily drive around your rented car in Mauritius.<br />
											<a name="driver"></a></p>
										<h3 className="text-secondary"><span className="accent">3.</span>Free Additional Driver</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-additional-driver.webp" alt="additional-driver" width="136" height="58" />
											We are flexible and offer free second driver option which is inclusive in your rental package. You just need to provide us with all the particulars of the additional driver during your pickup.<br />
											<a name="child"></a></p>
										<h3 className="text-secondary"><span className="accent">4.</span>&nbsp;Child Seats</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-car-seat.webp" alt="car-seat" width="136" height="58" />
											Your safety including that of your child is something we care a lot about. All our cars comes with child seat at competitive prices – simply specify it during your booking process with us.<br />
											<a name="insurance"></a>
										</p>
										<h3 className="text-secondary"><span className="accent">5.</span>All Comprehensive Insurance Cover</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-insurance.webp" alt="insurance" width="136" height="58" />
											Renting with us means you have an all comprehensive insurance which is inclusive in your rental package. You therefore limit your financial liability to the excess amount in cases of accidents.<br />
											<a name="road"></a>
										</p>
										<h3 className="text-secondary"><span className="accent">6.</span> 24 hours Roadside Assistance</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-assistance.webp" alt="assistance" width="136" height="58" />
											Our cars come with our roadside assistance available 24/7 in case of mechanical breakdown. Our team will get you back on the road if it means replacing your car with another one.<br />
											<a name="delivery"></a>
										</p>
										<h3 className="text-secondary"><span className="accent">7.</span>Free Delivery &amp; Pick up</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-keys.webp" alt="keys" width="136" height="58" />
											We offer free delivery and pickup to/from the Airport, your hotel or villa or any other custom locations. Airport deliveries are always free – hotel deliveries are free for more than 6+ days rental. Charges will vary depending seasons and locations.
										</p>
									</div>
								</article>



							</section>
						</div>

						<div className="sidebar col-md-4">
							<aside className="sidebar-widget">
								<h4 className="widget-title semi-bold">Benefits</h4>
								<hr />
								<div className="textwidget semi-bold">
									<div><i className="bi bi-check"></i>A-Class Fleet</div>
									<div><i className="bi bi-check"></i>Free Additional Driver</div>
									<div><i className="bi bi-check"></i>Free Child Seats</div>
									<div><i className="bi bi-check"></i>All Comprehensive Insurance Cover</div>
									<div><i className="bi bi-check"></i>24 hours Roadside Assistance</div>
									<div><i className="bi bi-check"></i>Free Delivery &amp; Pick up</div>
								</div>
							</aside>
							<aside className="sidebar-widget">
								<h4 className="widget-title mt-4 bold">Guaranteed Low Price</h4>
								<hr />
								<div className="textwidget">
									<div className="pricing-option active bold">
										<strong>Price starts at</strong>
										<div className="price-container text-center">
											<span className="dollar text-secondary">€</span>
											<span className="price text-secondary">25</span>
											<span className="terms text-secondary">/day</span>
										</div>
										<img className="d-block m-auto" src="../../images/pages/homecar.webp" alt="car" width="213" height="104" />

										<a href="/en" className="btn btn-primary w-100 text-white my-4">Search for a Car</a>

									</div></div>
							</aside>

						</div>

					</div>
			}

		</div>
	</div>);
}

export default Benefits;