import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import lang from "../lang/lang.json";

function OurEnterprise() {
	const params = useParams();

	return (
		<div className="ola-old-pages">
			<section className="internal-header">

				<div className="container">
					<div className="row v-align-children">
						<div className="col-md-8">
							<h1>
								{lang[params.lang]?.footer.ourEnterprise || "Our Enterprise"}
							</h1>
						</div>
						<div className="col-md-4 d-none d-md-block">
							<ol className="breadcrumb float-end">
								<li>
									<a href={params.lang ? `/${params.lang}/` : `en/`}>
										{
											params.lang == "fr" ?
												"Accueil"
												:
												"Home"
										}
									</a>
								</li>
								<li className="current">
									{lang[params.lang]?.footer.ourEnterprise || "Our Enterprise"}
								</li>
							</ol>
						</div>
					</div>
				</div>
			</section>

			<div className="container mt-2 mb-5">
				<div className="row">
					<div className="col-md-8">
						<section className="text-box">
							<article>
								<div className="text-center">
									<img className="m-2 float-lg-end" src="../../images/pages/our-enterprise.webp" alt="meet" width="387" height="327" />
								</div>
								<p>
									OLA Mauritius est une société de location de voitures longuement établie fournissant des voitures haut de gamme et un service d’une grande efficacité dans le domaine de location de voitures et nous sommes également la compagnie la plus compétitive du marché. Chez OLA Mauritius,nous nous engageons à servir nos clients au-delà de la norme d’excellence, loin devant nos concurrents.
								</p>
								<p>
									Nous visons à développer un service innovant de qualité en permanence et nous nous investissons pour rester numéro un en matière de location de voitures. Nous nous engageons à parcourir les kilomètres pour aller au-delà de vos attentes.
								</p>
								<blockquote className="blockquote">
									<p>
										La mission d’OLAMauritius est de réaliser votre rêve de découvrir l’île Maurice à travers une expérience unique de location de voiture en offrant un service exceptionnel grâce à notre dévouement, notre savoir-faire, notre professionnalisme et la qualité de notre service. Nous faisons tout notre possible pour faire plaisir à nos clients grâce à notre approche accueillante et bienveillante qui est unique.
									</p>
								</blockquote>
								<h3 className="text-secondary py-3">A-Class Fleet</h3>
								<ul className="aboutbox">
									<li><strong>Une assistance clientèle hors-pair</strong> Nous nous engageons à répondre aux besoins des clients et à honorer notre promesse.</li>
									<li><strong>Passion et dévouement</strong> Nous agissons avec enthousiasme et dévouement dans tout ce que nous entreprenons.</li>
									<li><strong>Innovation</strong> Nous nous efforçons toujours d’innover dans tout ce que nous faisons, que ce soit pour nos produits ou notre service.</li>
									<li><strong>Intégrité et Professionnalisme</strong> Nous travaillons de manière honnête et faisons preuve d’une éthique irréprochable.</li>
								</ul>
								<h3 className="text-secondary py-3">La qualité est pour nous une véritable culture</h3>
								<p>
									Plus qu’un simple concept, la quête de qualité est implantée dans notre société de location de voitures. La gestion de la qualité est ancrée dans la culture et la routine de l’équipe. Chez OLA,nous nous efforçons de trouver de nouveaux procédés afin de perpétuer cette culture de la qualité. Nous nous sommes inspirés des normes ISO, un système de gestion international qui définit les exigences et bonnes pratiques en gestion. En vue de promouvoir une culture d’amélioration continue et d’aller au-delà, nous avons établi un prix d’excellence pour nos employés afin de maximiser la productivité. Grâce à cette approche très stricte, nous garantissons une qualité exceptionnelle et nous sommes fiers d’être la seule agence de location de voiture épousant une telle approcheà l’île Maurice.
								</p>
							</article>
						</section>
					</div>
					<div className="sidebar col-md-4">
						<aside className="sidebar-widget">
							<h4 className="widget-title">Vos Plus</h4>
							<hr />
							<div className="textwidget"><ul>
								<li>Des voitures haut de gamme</li>
								<li>Un chauffeur supplémentaire gratuit</li>
								<li>Une assurance tous risques</li>
								<li>Une assistance routière de 24 heures</li>
								<li>Livraison et récupération gratuite</li>
							</ul>
							</div>
						</aside>
						<aside className="sidebar-widget">
							<h4 className="widget-title">Contact</h4>
							<hr />
							<div className="textwidget">
								<p>
									Vous n'arrivez pas à trouver la réponse à une question? Contactez-nous et l'un des membres de notre équipe se fera un plaisir de vous aider
								</p>
								<p>
									<i className="bi bi-phone"></i>
									<a className="text-secondary" href="tel:+230 5790 0575"> +230 5790 0575</a><br />
									<i className="bi bi-envelope"></i>
									<a className="text-secondary" href="mailto:info@olamauritius.com"> info@olamauritius.com</a>
								</p>
							</div>
						</aside>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OurEnterprise;