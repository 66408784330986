import React from 'react';
import { useParams } from 'react-router';
// import { useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Blogs from '../../components/Blogs';
import Faqs from '../../components/Faqs';
import Reviews from '../../components/Reviews';
import Locations from '../../components/Locations';
import Posts from '../../components/Posts';
import Hero from '../../components/Hero';

function Home() {
    const params = useParams();

    const exploreFleet = () => {
        document.getElementById("searchFormSubmitBtn").click();
    }

    return (<>
        <Helmet>
            <title>Manage Bookings</title>
            <meta name="description" content="OLA Mauritius Car Rental is an established car hire company that offers modern rental cars at a reasonable price, with Free GPS and Transfer." />
            <meta name="keywords" content="Rent a car, Car Rental, Hire Car" />
        </Helmet>
        <div className='home-container'>

            <Hero />

            <section className="hire-car-section py-5">
                {params.lang === "fr" ?
                    <div className="container">
                        <h2 className="text-center pt-2 pb-4 bold">
                            OLA location voiture ile Maurice- Livraison gratuit à l'aéroport.
                        </h2>
                        <div className="row">
                            <div className="section-content col-12 col-xl-9 col-12">
                                <p>
                                    Savourer vos vacances signifie embrasser la liberté de découvrir de nouvelles destinations à votre rythme. Avec OLA Mauritius, ce rêve devient une réalité. En choisissant notre service de location voiture île Maurice, vous pouvez sillonner les routes de l'île Maurice en toute tranquillité et à votre convenance. OLA Mauritius est fier de proposer une grande variété d’offres de location de voitures à des prix défiant toute concurrence. Notre gamme de véhicules comprend des voitures compactes pour les excursions en ville, des voitures familiales pour des aventures en groupe, et des voitures de luxe pour des occasions spéciales. Quels que soient vos besoins, notre service de ile maurice location voiture a la solution parfaite pour vous. Avec nos tarifs compétitifs, vous pouvez maximiser votre budget de vacances. Investissez dans des expériences inoubliables, des repas savoureux et des souvenirs précieux, tout en ayant l'assurance d'une solution de transport fiable et abordable avec OLA Mauritius. Alors, prenez le volant et commencez votre aventure à l'île Maurice. Profitez de la beauté naturelle de l'île, des plages étincelantes aux montagnes majestueuses, en passant par les villes animées. OLA Mauritius est là pour vous accompagner dans votre voyage.
                                </p>
                                <p>
                                    Location de voitures Ile Maurice
                                </p>
                                <p>
                                    À OLA Mauritius, notre mission est de transformer votre expérience de location de voiture ile maurice en un processus facile, économique et sans tracas. Nous comprenons que chaque client a des besoins et des attentes uniques lorsqu'il s'agit de louer une voiture ile maurice, c'est pourquoi nous proposons un large éventail de voitures pour tous les goûts et tous les budgets. Que vous ayez besoin d'une voiture compacte pour vos déplacements en ville, d'un véhicule familial pour voyager en groupe, ou d'un SUV robuste pour explorer les paysages spectaculaires de l'île, notre flotte diversifiée a tout pour plaire. Chaque véhicule de notre location voiture maurice est soigneusement entretenu et régulièrement vérifié pour vous garantir une expérience de conduite sûre et agréable. Mais notre engagement ne s'arrête pas à la fourniture de véhicules de qualité. À OLA Mauritius, nous sommes également fiers de nos prix compétitifs et de notre excellent service client. Nous nous engageons à offrir des tarifs de location de voitures transparents et abordables, sans frais cachés ni surprises de dernière minute. De plus, notre équipe de professionnels dévoués est toujours disponible pour répondre à vos questions, vous aider à choisir le véhicule idéal et vous assurer une expérience de location de voitures en douceur.
                                </p>
                                <a href="#" className="btn btn-outline-dark px-4">
                                    <i className="bi bi-car-front-fill pe-2"></i>
                                    Explorez la flotte
                                </a>
                            </div>
                            <div className="section-img col-12 col-xl-3 text-center mt-4">
                                <img src="../../../images/hire-car-img.webp" width="240" height="228" alt="A man enters the Ola Mauritius office" />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <h2 className="text-center pt-2 pb-4 bold">
                            Hire a Car in Mauritius
                        </h2>
                        <div className="row">
                            <div className="section-content col-12 col-xl-9 col-12">
                                <p>
                                    OLA is your trusted car rental partner in Mauritius offering great deals and prices with a quality service. Our attractive Mauritius car
                                    hire and Airport & Hotel transfer packages offer you the right solution in Mauritius.
                                </p>
                                <p className="semi-bold">
                                    Car Rental Mauritius
                                </p>
                                <p>
                                    We offer you the best car rental solution based on past customer’s feedbacks and their driving experiences in Mauritius. Our cars
                                    are recent and in excellent roadworthy condition with fully comprehensive insurance cover.
                                </p>
                                <p>
                                    Our fleet includes several categories of vehicles, which suits the Mauritian roads. From Mini, Economy, Sedans, MPV’s to luxury and
                                    convertible cars we have simply put a wide range of cars at your disposal. GPS, Car seats and other extras are offered at
                                    competitive prices!
                                </p>
                                <button onClick={() => exploreFleet()} className="btn btn-outline-dark px-4 semi-bold">
                                    <i className="bi bi-car-front-fill pe-2"></i>
                                    Explore Fleet
                                </button>
                            </div>
                            <div className="section-img col-12 col-xl-3 text-center mt-4">
                                <img src="../../../images/hire-car-img.webp" width="240" height="228" alt="A man enters the Ola Mauritius office" />
                            </div>
                        </div>
                    </div>
                }
            </section>
            <section className="reviews-section bg-secondary py-5">
                <div className="container pb-4">
                    <div className="">
                        <h2 className="pt-2 pb-4 px-4 bold">
                            Recent Client Reviews
                        </h2>

                        <Reviews />

                    </div>
                </div>
            </section>
            <section className="why-section py-5">
                {params.lang === "fr" ?
                    <div className="container">
                        <div className="px-3">
                            <h2 className="text-center pt-2 pb-4 bold">
                                Pourquoi OLA location voiture
                            </h2>
                            <div className="why-section-text text-center">
                                <p className="mb-lg-4">
                                    Profitez à nos offres les plus avantageux avec un service de qualité à des prix défiant toute concurrence à l'ile Maurice.
                                </p>
                            </div>
                        </div>
                        <div className="row pt-5 pb-4 d-flex justify-content-around">
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-speedometer.svg" alt="speedometer" />
                                </div>
                                <span>
                                    Kilométrage Illimité
                                </span>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-counter.svg" alt="counter" />
                                </div>
                                <span>
                                    Bureau/comptoir à l'aéroport
                                </span>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-shield-star.svg" alt="shield with star" />
                                </div>
                                <span>
                                    Assurance tous risques
                                </span>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-airport-pick.svg" alt="airport pick" />
                                </div>
                                <span>
                                    Prise en charge et livraisons gratuites à l'aéroport
                                </span>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-credit-card.svg" alt="credit card" />
                                </div>
                                <span>
                                    Réservez maintenant et payez à l'arrivée
                                </span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div className="px-3">
                            <h2 className="text-center pt-2 pb-4 bold">
                                Why OLA Mauritius?
                            </h2>
                            <div className="why-section-text text-center">
                                <p className="mb-lg-4">
                                    Choose OLA Mauritius for your Mauritius car hire needs, where our commitment to quality, convenience, and customer satisfaction shines through in every aspect of our service. We take immense pride in offering reliable and efficient transport solutions to enhance your experience in Mauritius.
                                </p>
                                <p className="mb-lg-4">
                                    Our car rental options cater to a diverse range of needs and budgets. Whether you're in Mauritius for a brief business engagement or a leisurely holiday, OLA Mauritius provides a fleet that combines comfort, safety, and luxury.
                                </p>
                                <p className="mb-lg-4">
                                    Our vehicles are perfect for navigating the island's picturesque landscapes, ensuring your journeys are as enjoyable as the destination itself.
                                </p>
                                <p className="mb-lg-4">
                                    With OLA Mauritius, you not only get the best car hire deals but also the peace of mind that comes with a top-notch rental car experience.
                                </p>
                                <a href="#" className="btn text-secondary px-1 py-0 bold">
                                    Read more
                                </a>
                            </div>
                        </div>
                        <div className="row pt-5 pb-4 d-flex justify-content-around">
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-speedometer.svg" alt="speedometer" />
                                </div>
                                <span className='bold'>
                                    Unlimited Mileage on all vehicles
                                </span>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-counter.svg" alt="counter" />
                                </div>
                                <span className='bold'>
                                    Office / counter at the airport
                                </span>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-shield-star.svg" alt="shield with star" />
                                </div>
                                <span className='bold'>
                                    Free comprehensive Insurance
                                </span>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-airport-pick.svg" alt="airport pick" />
                                </div>
                                <span className='bold'>
                                    Free airport pickup and deliveries
                                </span>
                            </div>
                            <div className="col-12 col-md-4 col-xl-2 pt-4 text-center">
                                <div className="p-2">
                                    <img src="../../../images/icon-credit-card.svg" alt="credit card" />
                                </div>
                                <span className='bold'>
                                    Book now and pay on arrival
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </section>

            <Blogs />

            <Posts />

            <Faqs />

            <Locations />

        </div>
    </>);
}

export default Home;