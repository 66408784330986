import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { formatDate } from '../utils/dateUtils';

import lang from "../lang/lang.json";
import { fetchReviews } from '../services/apiReviewsService';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Reviews() {
    const params = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [reviews, setReviews] = useState(false);

    useEffect(() => {
        if (!reviews.length) {
            // Timeout needs to mimic asynchrone Reviews loading
            setTimeout(() => {
                getReviewList();
            }, 0);
        }
    }, []);

    let getReviewList = async () => {
        let results = await fetchReviews(axiosPrivate);
        console.log("Reviews: ", results)
        results = results?.data
        // use "en" records when there are no records for this lang param
        if (params.lang && results[params.lang]?.length > 0) {
            results = results[params.lang];
        } else {
            results = results["en"];
        }
        let reviewsTemp = [];
        if (results && results.length > 0) {
            for (let i = 0; i < results.length; i++) {
                reviewsTemp.push(
                    <div key={"key-reviews-" + i} className="card card__horizontal-review my-4">
                        {!!results[i].imgUrl?.length > 0 ?
                            <img src={results[i].imgUrl ?? "../../../images/icon-person-circle.svg"} alt="avatar" className="card-img" width="65" height="65" />
                            :
                            <img src="../../../images/icon-person-circle.svg" alt="avatar" className="card-img" />
                        }
                        <div className="card-body">
                            <div className="card-date semi-bold">{results[i].timestamp && formatDate(new Date(results[i].timestamp), "DD MMM, YYYY")}</div>
                            {!!results[i].title?.length > 0 ?
                                <h3 className="card-title text-secondary semi-bold">{results[i].title}</h3>
                                :
                                ""
                            }
                            <p className="card-text">
                                {results[i].text}
                            </p>
                            <div className="card-title semi-bold">{results[i].author}</div>
                        </div>
                    </div>

                );
            }

            if (reviewsTemp.length > 0) {
                setReviews(reviewsTemp);
            }
        }
    }

    return (
        <section className="why-section bg-secondary py-5">
            <div className="container">
                <h2 className="text-center pt-2 pb-4 bold">
                    {lang[params.lang]?.pages.reviews.title || "Testimonials"}
                </h2>
                <p>
                    {
                        lang[params.lang]?.pages.reviews.intro ||
                        "We strive to keep everyone happy by going the extra mile. Below is a collection of reviews and comments left by our clients. Please note that these reviews have been either emailed to us or sourced from third-party websites."
                    }
                </p>

                {reviews}
            </div>
        </section>
    );
}

export default Reviews;