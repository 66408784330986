import React, { useState, useEffect } from 'react';
import {
    Link,
    useParams,
    useLocation,
    useSearchParams,
    useOutletContext
} from "react-router-dom";
import { Helmet } from "react-helmet";

import { getCar, postBooking } from '../../services/apiCarsService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SearchForm from "../../components/SearchForm";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function Checkout() {
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [car, setCar] = useState(false);
    const [duration, setDuration] = useState(false);
    const [pickupLoc, setPickupLoc] = useState('');
    const [dropOffLoc, setDropOffLoc] = useState('');
    const [diffLoc, setDiffLoc] = useState(false);
    const [pickupDate, setPickupDate] = useState('');
    const [dropOffDate, setDropOffDate] = useState('');
    const [pickupTime, setPickupTime] = useState('');
    const [dropOffTime, setDropOffTime] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [airlineName, setAirlineName] = useState(false);
    const [flightNumber, setFlightNumber] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpiryDate, setCardExpiryDate] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [options, setOptions] = useState(false);
    const [optionsJSON, setOptionsJSON] = useState([]);
    const [totalPrice, setTotalPrice] = useState(false);
    const [isFormInEdit, setIsFormInEdit] = useState(false);
    const [isCompleteDisabled, setIsCompleteDisabled] = useState(false);
    const contextValue = useOutletContext()
    const [checkoutData, setCheckoutData] = contextValue.checkoutData
    const [fetchedData, setFetchedData] = contextValue.fetchedData

    useEffect(() => {
        // Timeout needs to mimic asynchrone cars loading
        setTimeout(() => {
            getResult();
        }, 0);
    }, [searchParams]);

    useEffect(() => {

        let pickupLoc = searchParams.get('pickupLoc');
        switch (pickupLoc) {
            case "airport":
                setPickupLoc("Airport, Mauritius");
                break;
            case "hotel":
                setPickupLoc("Hotel Delivery");
                break;
            case "other":
                setPickupLoc("Other Location");
                break;
            default:
                setPickupLoc(pickupLoc);
                break;
        }
        let dropoffLoc = searchParams.get('dropoffLoc');
        switch (dropoffLoc) {
            case "airport":
                setDropOffLoc("Airport, Mauritius");
                break;
            case "hotel":
                setDropOffLoc("Hotel Delivery");
                break;
            case "other":
                setDropOffLoc("Other Location");
                break;
            default:
                setDropOffLoc(dropoffLoc);
                break;
        }
        setDiffLoc(searchParams.get('diffLoc') === "1");

        if (!searchParams.get('start') || !searchParams.get('end')) {
            return;
        }

        let startDate = new Date(searchParams.get('start'));
        setPickupDate(startDate.toLocaleDateString('en-CA'));
        setPickupTime(searchParams.get('start').substring(11, 16));

        let endDate = new Date(searchParams.get('end'));
        setDropOffDate(endDate.toLocaleDateString('en-CA'));
        setDropOffTime(searchParams.get('end').substring(11, 16));

    }, [searchParams]);



    // check form validation
    // useEffect(() => {
    //     if (!firstName || !lastName || !emailAddress || !phoneNumber || !airlineName || !flightNumber) {
    //         setIsCompleteDisabled(true);
    //         return;
    //     }

    //     if (!cardNumber || !cardExpiryDate || !cardCVV) {
    //         setIsCompleteDisabled(true);
    //         return;
    //     }

    //     // if (!phoneNumber || phoneNumber.length < 6 ) {
    //     //     setIsCompleteDisabled(true);
    //     //     return;
    //     // }

    //     setIsCompleteDisabled(false);
    // }, [
    //     firstName,
    //     lastName,
    //     emailAddress,
    //     phoneNumber,
    //     airlineName,
    //     flightNumber,
    //     cardNumber,
    //     cardExpiryDate,
    //     cardCVV
    // ]);

    // // check form phoneNumber
    // useEffect(() => {
    //     console.log("phoneNumber: ", phoneNumber)
    // }, [phoneNumber]);


    let getResult = async () => {
        let carId = searchParams.get("id");
        let result
        if(fetchedData.data){
            result = fetchedData
        }
        else{
            result = await getCar(axiosPrivate, carId, location.search);
        }
        if (!result.data?.vehicle) {
            return
        }
        setCar(result.data?.vehicle);

        let totalPriceTemp = Math.round(result.data?.vehicle.prices.total);
        let options = searchParams.get('options');
        if (!!options) {
            console.log("in options: ", options)
            let carOptions = result.data?.vehicle.options;
            let optionsTemp = [];
            let optionsJSONTemp = [];
            options = options.split('id')

            options.forEach(option => {
                if (option.length < 1) {
                    return;
                }

                let keyValues = option.split('value');

                let selectedOption = carOptions.find((option) => option.id.toString() === keyValues[0].toString());
                let quantity = Number(keyValues[1]);
                let price = selectedOption.price * quantity;

                optionsTemp.push(
                    <div className="d-flex" key={"key-option-" + selectedOption.id}>
                        <div className="">
                            {selectedOption.name}
                        </div>
                        <div className="ms-auto">
                            € {price}
                        </div>
                    </div>
                )

                optionsJSONTemp.push(
                    {
                        id: selectedOption.id,
                        quantity: quantity,
                    }
                );

                totalPriceTemp += price;

            });

            setOptions(optionsTemp);
            setOptionsJSON(optionsJSONTemp);
        }

        setTotalPrice(totalPriceTemp.toFixed(2));
        setCheckoutData(prevState => { return {...prevState, finalPrice: totalPriceTemp}})

        if (!result.data?.duration.days) {
            return
        }
        setDuration(result.data?.duration.days)
    }

    let createBooking = async (e) => {
        e.preventDefault();

        // Phone number validation
        let phoneNumberInput = document.querySelector(".react-tel-input-wrp input");
        if (phoneNumber.length < 6) {
            phoneNumberInput.focus();
            return;
        }

        let body = {
            id: searchParams.get('id'),
            fullName: `${firstName} ${lastName}`,
            address: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            email: emailAddress,
            phone: phoneNumber,
            flightNumber: flightNumber,
            pickupDate: searchParams.get('start'),
            dropoffDate: searchParams.get('end'),
            pickupLocation: searchParams.get('pickupLoc'),
            dropoffLocation: searchParams.get('dropoffLoc'),
            diffLocation: Number(searchParams.get('diffLoc')),
            age: searchParams.get('age'),
            pickupDetail: "",
            dropoffDetail: "",
            products: optionsJSON,
            // [
            //     {
            //         id: "27",
            //         description: "description",
            //         quantity: "1",
            //     },
            //     {
            //         id: "11",
            //         description: "description",
            //         quantity: "2",
            //     }
            // ],

            // additional
            airlineName: airlineName,
        };
        let booking = await postBooking(axiosPrivate, body);
        console.log("booking: ", booking)

        if (booking) {
            let successAlert = document.getElementById('alert-success-booking');
            successAlert.classList.remove('d-none');
            successAlert.scrollIntoView();
            setCheckoutData(prevState => { return {...prevState, bookDisabled: true}})
        }

        // if (!result.data?.vehicle) {
        //     return
        // }
        // setCar(result.data?.vehicle);

        // if (!result.data?.duration.days) {
        //     return
        // }
        // setDuration(result.data?.duration.days)
    }

    return (<>

        <Helmet>
            <title>Choose your vehicle</title>
            <meta name="description" content="OLA Mauritius Car Rental is an established car hire company that offers modern rental cars at a reasonable price, with Free GPS and Transfer." />
            <meta name="keywords" content="Rent a car, Car Rental, Hire Car" />
        </Helmet>

        <div className="bg-gray pb-5">
            {car ?
                <div className="results-container container-xxl pb-5">

                    <div className="cars-header gap-4">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="card rounded-0 rounded-bottom">
                                    <div className="card-body">
                                        <div className="cars-header-title pb-4 semi-bold">
                                            1. SEARCH RESULTS
                                        </div>
                                        <div className="">
                                            To re-do this part that tracks the progress
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="collapse" id="searchFormToggle">
                                    <div className="card">
                                        <div className="card-body">
                                            <SearchForm setPickup={setPickupLoc} setDropOff={setDropOffLoc} setDiff={setDiffLoc}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className="py-2 mt-3 mb-0 bold">
                        Review & Reserve
                    </h2>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card card__border p-3 my-4">
                                <div className="card-body">
                                    <h5 className="bold pb-2">
                                        Rental Details
                                    </h5>
                                    <div className="d-flex align-items-center">
                                        <div className="semi-bold mb-2">
                                            Dates & Times
                                        </div>
                                        <div className="ms-auto">
                                            <button
                                                className="btn btn-outline-secondary w-100 px-3 semi-bold"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#searchFormToggle"
                                                aria-controls="searchFormToggle"
                                                aria-expanded="false"
                                                aria-label="Toggle Search Form"
                                                onClick={() => { setIsFormInEdit(!isFormInEdit) }}
                                                disabled={isFormInEdit}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>

                                    <div className="booking-summary-location">
                                        {pickupDate}, at {pickupTime}
                                    </div>
                                    <div className="booking-summary-location">
                                        {dropOffDate}, at {dropOffTime}
                                    </div>

                                    <div className="d-flex align-items-center mt-3 mb-2">
                                        <div className="semi-bold">
                                            Pickup & Return Location
                                        </div>
                                        <div className="ms-auto">
                                            <button
                                                className="btn btn-outline-secondary w-100 px-3 semi-bold"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#searchFormToggle"
                                                aria-controls="searchFormToggle"
                                                aria-expanded="false"
                                                aria-label="Toggle Search Form"
                                                onClick={() => { setIsFormInEdit(!isFormInEdit) }}
                                                disabled={isFormInEdit}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>


                                    <div className="">
                                        {
                                            !diffLoc ? `Both at ${pickupLoc}`
                                                :
                                                <div className="booking-summary-location">
                                                    Pickup  {pickupLoc}
                                                    <br />
                                                    Return {dropOffLoc}
                                                </div>
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="card card__border p-3 my-4">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <div className="semi-bold">
                                                {car.make} or similar
                                            </div>
                                            <div className="text-muted">
                                                {car.gearbox} transmission
                                            </div>
                                        </div>
                                        <div className=" ms-auto">
                                            <img src={car.photoMain} width={180} alt='Large Pizza' />
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex mb-3">
                                        <div className="">
                                            Rental for {duration} days
                                        </div>
                                        <div className="ms-auto">
                                            € {Math.round(car.prices.total).toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="bold mb-2">
                                        Extras
                                    </div>
                                    {/* <div className="d-flex">
                                        <div className="">
                                            Damage Waiver
                                        </div>
                                        <div className="ms-auto">
                                            € 45.00
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="">
                                            Booster Seat
                                        </div>
                                        <div className="ms-auto">
                                            € 15.34
                                        </div>
                                    </div> */}
                                    {options}
                                    <hr></hr>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="bold">
                                            Total price
                                        </div>
                                        <h4 className="bold ms-auto">
                                            € {totalPrice}
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card card__border bg-secondary p-3 my-3">
                                <div className="card-body">
                                    <h6 className="cars-header-title bold pb-2">
                                        Why Choose Us?
                                    </h6>
                                    <div className="semi-bold">
                                        <div className="my-2">
                                            <i className="bi bi-check-lg text-green"></i> Free Cancellation Anytime
                                        </div>
                                        <div className="my-2">
                                            <i className="bi bi-check-lg text-green"></i> Pay at Pickup
                                        </div>
                                        <div className="mt-2">
                                            <i className="bi bi-check-lg text-green"></i> Unlimited Mileage
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">

                            <form id="submitCheckout" onSubmit={createBooking}>
                                <div className="card card__border p-3 my-4">
                                    <div className="card-body">
                                        <h5 className="bold pb-2">
                                            Contact Details
                                        </h5>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName" className="bold mt-3 mb-2">First Name*</label>
                                                <input type="text" name="firstName" className="input__checkout w-100"
                                                    value={firstName} onChange={(e) => setFirstName(e.target.value)}
                                                    required
                                                ></input>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="lastName" className="bold mt-3 mb-2">Last Name*</label>
                                                <input type="text" name="lastName" className="input__checkout w-100"
                                                    value={lastName} onChange={(e) => setLastName(e.target.value)}
                                                    required
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="email" className="bold mt-3 mb-2">Email Address*</label>
                                                <input type="email" name="email" className="input__checkout w-100"
                                                    value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)}
                                                    required
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 react-tel-input-wrp">
                                                <label className="bold mt-3 mb-2">Phone Number*</label>
                                                {/* <input type="number" name="phoneNumber"
                                                    className="input__checkout w-100 input--number-as-text"
                                                    value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                                                    required
                                                ></input> */}
                                                <PhoneInput
                                                    country={"eg"}
                                                    enableSearch={true}
                                                    value={phoneNumber}
                                                    onChange={(phone) => setPhoneNumber(phone)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card__border p-3 my-4">
                                    <div className="card-body">
                                        <h5 className="bold pb-2">
                                            Flight Details
                                        </h5>
                                        <p>
                                            Providing your flight information will help us keep your car ready upon arrival
                                        </p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="airlineName" className="bold mt-3 mb-2">Airline Name</label>
                                                {/* <input type="text" name="airlineName" className="input__checkout w-100"></input> */}

                                                <select className="form-select custom-select font-weight-bold input__checkout w-100"
                                                    aria-label="Airline Name"
                                                    name="airlineName"
                                                    value={airlineName} onChange={(e) => setAirlineName(e.target.value)}>
                                                    <option defaultValue value="">All Airlines</option>
                                                    <option value="Discover Airlines (4Y)">Discover Airlines (4Y)</option>
                                                    <option value="Air Canada (AC)">Air Canada (AC)</option>
                                                    <option value="Air France (AF)">Air France (AF)</option>
                                                    <option value="Air India (AI)">Air India (AI)</option>
                                                    <option value="British Airways (BA)">British Airways (BA)</option>
                                                    <option value="Condor (DE)">Condor (DE)</option>
                                                    <option value="Emirates (EK)">Emirates (EK)</option>
                                                    <option value="KLM (KL)">KLM (KL)</option>
                                                    <option value="Kenya Airways (KQ)">Kenya Airways (KQ)</option>
                                                    <option value="Lufthansa (LH)">Lufthansa (LH)</option>
                                                    <option value="Swiss (LX)">Swiss (LX)</option>
                                                    <option value="Austrian Airlines (OS)">Austrian Airlines (OS)</option>
                                                    <option value="Brussels Airlines (SN)">Brussels Airlines (SN)</option>
                                                    <option value="Saudia (SV)">Saudia (SV)</option>
                                                    <option value="Turkish Airlines (TK)">Turkish Airlines (TK)</option>
                                                    <option value="Aeroflot (SU)">Aeroflot (SU)</option>
                                                    <option value="EgyptAir (MS)">EgyptAir (MS)</option>
                                                    <option value="Air Mauritius (MK)">Air Mauritius (MK)</option>
                                                    <option value="Vistara (UK)">Vistara (UK)</option>
                                                    <option value="Neos (NO)">Neos (NO)</option>
                                                    <option value="Air Austral (UU)">Air Austral (UU)</option>
                                                    <option value="Air Seychelles (HM)">Air Seychelles (HM)</option>
                                                    <option value="Corsair International (SS)">Corsair International (SS)</option>
                                                    <option value="Evelop Airlines (E9)">Evelop Airlines (E9)</option>
                                                    <option value="Safair (FA)">Safair (FA)</option>
                                                </select>

                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="flightNumber" className="bold mt-3 mb-2">Flight Number</label>
                                                <input type="text" name="flightNumber" className="input__checkout w-100"
                                                    placeholder='AB 123'
                                                    value={flightNumber} onChange={(e) => setFlightNumber(e.target.value)}
                                                    required
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card__border p-3 my-4">
                                    <div className="card-body">
                                        <h5 className="bold pb-0">
                                            Payment Details
                                        </h5>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="carNumber" className="bold mt-3 mb-2">Card Number</label>
                                                <input type="number" name="carNumber" className="input__checkout w-100 input--number-as-text"
                                                    placeholder="Card Number"
                                                    value={cardNumber} onChange={(e) => setCardNumber(e.target.value)}
                                                    required
                                                ></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="expiryDate" className="bold mt-3 mb-2">Expiry Date</label>
                                                <input type="text" name="expiryDate" className="input__checkout w-100" placeholder="MM/YY"
                                                    value={cardExpiryDate} onChange={(e) => setCardExpiryDate(e.target.value)}
                                                    required
                                                ></input>
                                            </div>
                                            <div className="col-md-2">
                                                <label htmlFor="cvv" className="bold mt-3 mb-2">CVV</label>
                                                <input type="number" name="cvv" className="input__checkout w-100 input--number-as-text" placeholder="123"
                                                    value={cardCVV} onChange={(e) => setCardCVV(e.target.value)}
                                                    required
                                                ></input>
                                            </div>
                                        </div>
                                        <p className="my-3">
                                            Your credit card will only be validated and not charged. The full payment is expected at the time of collecting your rental
                                        </p>
                                        <div className="">
                                            <img src="../../images/creditcardicons.webp" height={30} alt="credit cards" />
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        {/* TO DO add selected options and quantity */}
                        {/* <Link
                            to={`/${params.lang}/checkout${location.search}`}
                            className="btn btn-primary fw-bold" type="submit" >
                            Complete
                        </Link> */}
                        <div className="col-12">
                            <div id="alert-success-booking" className="alert alert-success align-items-center d-none" role="alert">
                                <div className="text-center">
                                    The booking has been made!
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                :
                <div className="m-5 p-5">
                    <h3>Loading Booking form...</h3>
                </div>
            }
        </div>
    </>);
}

export default Checkout;