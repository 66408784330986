import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

function Terms() {
    const params = useParams();

    return (<div className="ola-old-pages">
        <section className="internal-header">

            <div className="container">
                <div className="row v-align-children">
                    <div className="col-md-8">
                        <h1>
                            {
                                params.lang == "fr" ?
                                    "Conditions Générales"
                                    :
                                    "Terms and Conditions"
                            }
                        </h1>
                    </div>

                    <div className="col-md-4 d-none d-md-block">
                        {
                            params.lang == "fr" ?
                                <ol className="breadcrumb float-end">
                                    <li>
                                        <a href="/fr/">Accueil</a></li><li className="current">Conditions Générales</li>
                                </ol>
                                :
                                <ol className="breadcrumb float-end">
                                    <li><a href="/en/">Home</a></li><li className="current">Terms and Conditions</li>
                                </ol>
                        }
                    </div>

                </div>
            </div>
        </section>

        <div className="container mt-2 mb-5">

            {
                params.lang == "fr" ?
                    <div className="row">
                        <div className="col-md-8">
                            <section className="text-box">
                                <article>
                                    <p>Cet accord est conclu entre le locataire et la compagnie OLA Rent A Car Ltd, constituée en vertu des lois de la République de Maurice.</p>
                                    <h3>1. Général</h3>
                                    <ul>
                                        <li>«Locataire» par rapport à un véhicule est un parti qui signe le contrat de location ou tout conducteur supplémentaire apte à conduire le véhicule loué à l’île Maurice.</li>
                                        <li>“OLA Rent A Car Ltd” est une société enregistrée dans le cadre juridique de l’île Maurice et mentionnée dans le contrat de location.</li>
                                        <li>«Véhicule» désigne toute voiture, van, scooter ou autre véhicule à propulsion mécanique destiné à toute période de location convenue.</li>
                                        <li>«Dommage» signifie tout dommage(s) causé(s) au véhicule loué ou biens d’un tiers par collision, vol ou par quelque moyen altérant son état initial.</li>
                                    </ul>
                                    <h3>2. Prérequis</h3>
                                    <ul>
                                        <li>Le locataire doit présenter un permis de conduire valide, délivré par les autorités compétentes, qui doit dater d’au moins 12 mois avant la date de location. Un permis de conduire international devra toutefois être présenté avec le permis de conduire normal dans le cas où il est dans une langue autre que l’anglais.</li>
                                        <li>Le locataire doit également présenter son passeport comme preuve d’identité.</li>
                                        <li>Le locataire doit aussi présenter une carte de crédit valide (Visa, MasterCard) pour le règlement intégral des frais de location en cours et le dépôt remboursable de l’excès d’assurance.</li>
                                        <li>Le paiement de la caution remboursable est obligatoire et est accepté en espèces ou par carte de crédit avec autorisation préalable.</li>
                                    </ul>
                                    <h3>3. VÉHICULE: Inspection, Utilisation</h3>
                                    <h4 className="text-secondary">3.1 Inspection</h4>
                                    <ul>
                                        <li>Une inspection conjointe de pré-location doit être effectuée avant que le locataire ne prenne en charge le véhicule et tous les vices apparents et le niveau de carburant doivent être répertoriés et signés par les deux parties.</li>
                                    </ul>
                                    <h4 className="text-secondary">3.2 Utilisation des véhicules</h4>
                                    <ul>
                                        <li>Le locataire doit utiliser le véhicule de manière responsable avec soin et attention et le maintenir en bon état de marche.</li>
                                        <li>Le locataire est également responsable de tous les dispositifs supplémentaires comme le GPS, les sièges auto et doit restituer les dispositifs dans le même état qu’à l’initial.</li>
                                        <li>Le véhicule doit toujours être verrouillé lorsqu’il est laissé sans surveillance. Par la suite, toute perte ou dommage causé à un véhicule non verrouillé sera à la charge du locataire.</li>
                                        <li>Le véhicule est livré impeccablement propre et doit être restitué, autant que possible, dans le même état de propreté.</li>
                                        <li>Le locataire doit se conformer à la loi sur la circulation routière en vigueur à Maurice et est passible d’amendes éventuelles résultant de tout manquement en conséquence au cours de la période de location.</li>
                                        <li>Le véhicule doit être restitué dans le même état qu’au début de la location et le locataire sera tenu de rembourser OLA Rent A Car Ltd pour tout dommage(s) causé(s) au véhicule, et devra rembourser OLA Rent A Car Ltd pour tous les frais encourus.</li>
                                    </ul>
                                    <h4 className="text-secondary">3.3 Entretien / Problèmes mécaniques</h4>
                                    <ul>
                                        <li>Tous les entretiens et réparations mécaniques sont effectués par OLA Rent A Car Ltd ou, au cas contraire, avec l’approbation écrite d’OLA Rent A Car Ltd. Les frais et coûts de toutes réparation(s) entreprises sans un consentement écrit ne seront pas remboursés au locataire.</li>
                                        <li>Tous les véhicules sont loués en parfait état de marche. Cependant, le locataire doit immédiatement arrêter le véhicule dans le cas d’une anomalie indiquée par les voyants du tableau de bord ou pour toute autre raison si le locataire estime que des problèmes mécaniques pourraient survenir. Le locataire doit informer immédiatement OLA Rent A Car Ltd de toute anomalie ou problème mécanique.</li>
                                    </ul>
                                    <h4 className="text-secondary">3.4 Assistance routière</h4>
                                    <ul>
                                        <li>Au cours de la période de location, le locataire a droit à une assistance routière de 24 heures en cas de panne ou d’accident. Cependant, en cas de négligence le locataire devra encourir des frais supplémentaires.</li>
                                        <li>OLA Rent A Car Ltd s’engage à remplacer tout véhicule accidenté ou en panne ou à procurer un taxi à ses propres frais à la convenance du locataire en tenant compte de ce qui est le plus approprié.</li>
                                    </ul>
                                    <h3>4. Période de location</h3>
                                    <ul>
                                        <li>Le locataire doit restituer le véhicule à l’endroit, la date et l’heure convenus mentionnés dans le contrat de location.</li>
                                        <li>La prolongation de la période de location doit être notifiée à OLA Rent A Car Ltd au moins 2 jours avant la fin de la date de location.</li>
                                        <li>Le tarif de location est calculé sur une base quotidienne ainsi qu’une base horaire. Une grâce de 30 minutes est normalement appliquée à la fin de la période de location, ensuite le locataire sera facturé pour les heures supplémentaires (3 euros / heure).</li>
                                    </ul>
                                    <h3>5. ACCIDENT</h3>
                                    <ul>
                                        <li>En cas d’accident le locataire doit immédiatement informer OLA Rent A Car Ltd et demander des conseils sur la démarche à suivre.</li>
                                        <li>Dans le contexte mauricien où un accident se produit et n’entraîne pas de lésions corporelles et où il n’y a pas plus de deux véhicules inclus, les parties concernées doivent procéder mutuellement à un constat à l’amiable.</li>
                                        <li>En cas de complexité, l’équipe d’urgence d’OLA interviendra dans les plus brefs délais et prendra en charge la situation.</li>
                                        <li>Dans le cas où le véhicule n’est pas en état de rouler, suite à un accident, OLA Rent A Car Ltd s’engage à remplacer votre voiture par un autre de la même catégorie.</li>
                                    </ul>
                                    <h3>6. Modalités de paiement</h3>
                                    <ul>
                                        <li>25% du montant total de la location est nécessaire pour confirmer une réservation chez OLA Rent A Car Ltd</li>
                                        <li>Le reste doit être réglé au moment de la prise par le locataire soit en espèces (euros ou roupies mauriciennes) ou carte de crédit. Cependant, une commission de 5% s’applique en sus des frais totaux de location en tant que frais bancaires si le paiement est effectué par carte de crédit.</li>
                                    </ul>
                                    <h3>7. Annulation et remboursement</h3>
                                    <ul>
                                        <li>En cas d’annulation a un délai de 7 jours avant la prise la totalité de votre depot serait remboursable . Pour toute annulation de moins de 5 &nbsp;jours aucun remboursement sera accepte.</li>
                                        <li>Il n’y aura aucun remboursement sur les jours de location où le véhicule n’a pas été utilisé.</li>
                                    </ul>
                                    <h3>8. Assurance</h3>
                                    <ul>
                                        <li>OLA Rent A Car Ltd fournit une police d’assurance contre les dommages corporels ou les dommages à la propriété de tiers résultant d’un accident impliquant son véhicule en vertu d’un contrat de location. Seuls les conducteurs autorisés à conduire seront couverts par l’assurance.</li>
                                        <li>Un conducteur impliqué dans un incident, où les dégâts en résultant, est exclu de la couverture complète en cas de conduite en état d’ivresse ou de négligence grave.</li>
                                        <li>La perte des clés du véhicule et des effets personnels ne sont pas couverts par la police d’assurance.</li>
                                    </ul>
                                    <h3>9. Responsabilité</h3>
                                    <ul>
                                        <li>Le locataire ou tout conducteur supplémentaire est passible d’amendes ou contraventions en vertu de la loi sur la circulation routière pendant la période de location.</li>
                                        <li>Le locataire est responsable de tout dommage(s) si le véhicule est restitué dans un état autre que celui énuméré au cours du processus d’inspection conjointe de pré-location.</li>
                                    </ul>
                                </article>
                            </section>
                        </div>
                        <div className="sidebar col-md-4">
                            <aside className="sidebar-widget">
                                <h4 className="widget-title">Vos Plus</h4>
                                <hr />
                                <div className="textwidget"><ul>
                                    <li>Des voitures haut de gamme</li>
                                    <li>Un chauffeur supplémentaire gratuit</li>
                                    <li>Une assurance tous risques</li>
                                    <li>Une assistance routière de 24 heures</li>
                                    <li>Livraison et récupération gratuite</li>
                                </ul>
                                </div>
                            </aside>
                            <aside className="sidebar-widget">
                                <h4 className="widget-title">Contact</h4>
                                <hr />
                                <div className="textwidget">
                                    <p>
                                        Vous n'arrivez pas à trouver la réponse à une question? Contactez-nous et l'un des membres de notre équipe se fera un plaisir de vous aider
                                    </p>
                                    <p>
                                        <i className="bi bi-phone"></i>
                                        <a className="text-secondary" href="tel:+230 5790 0575"> +230 5790 0575</a><br />
                                        <i className="bi bi-envelope"></i>
                                        <a className="text-secondary" href="mailto:info@olamauritius.com"> info@olamauritius.com</a>
                                    </p>
                                </div>
                            </aside>
                        </div>
                    </div>
                    :
                    <div className="row mt-2">
                        <div className="col-sm-12 text-left">
                            <section className="text-box">
                                <article>
                                    <h3>1. General</h3>
                                    <ul>
                                        <li>“Renter” in relation to a vehicle means is any party who signs the rental agreement OR any additional driver competent to drive the rented vehicle in Mauritius.</li>
                                        <li>“OLA (MTIUS) Co Ltd” is a registered company under the legal framework of Mauritius &amp; mentioned in the rental agreement.</li>
                                        <li>“Vehicle” means any car, van, scooter or any mechanically propelled vehicle for the purpose of any agreed rental period.</li>
                                        <li>“Damage” means any damage(s) caused to the rented vehicle or any third party property by collision, theft or by any means thus altering its initial condition.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <h3>2. Mandatory documents</h3>
                                    <ul>
                                        <li>Renter must produce a valid driving licence issued by competent authorities and be at least 12 months before the rental date. An international driving licence should however be produced together with the normal driving licence in the event it is in any languages other than Roman alphabets (Chinese, Arabic,Japanese etc).</li>
                                        <li>International driving licence is not compulsory if you’ll be visiting Mauritius for a period of less than 3 months.</li>
                                        <li>Renter shall after completing his/her booking upload his/her driving licence and passport or send these documents by email for our records.</li>
                                        <li>Renter must also produce originals of his/her driving licence and passport as a proof of identity.</li>
                                        <li>Renter must also present a valid <strong>Credit Card</strong> (Visa, MasterCard, Amex) for the full settlement of outstanding rental fees and for the pre authorisation of the excess/deductible/security deposit. Debit cards are not accepted.</li>
                                        <li>Excess deposit refundable payment is mandatory and is accepted in either cash or by pre authorization by credit&nbsp;card.</li>
                                        <li>Excess deposit may take up to 15 working days before being released as per banking procedures.</li>
                                        <li>Excess deposit is also accepted in cash and will be refunded upon drop off if car is returned in the same condition at pick up time.</li>
                                        <li>OLA (MTIUS) Co Ltd will not release any cars if the excess amount is not provided upon delivery of vehicle.</li>
                                        <li>The excess amount &nbsp;varies from 20000 to 30000 MUR depending on car categories or models.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <h4 className="text-secondary">2.1 Booking: Online, Email, phone</h4>
                                    <p><strong>&nbsp;</strong><strong>&nbsp;</strong>Car can be booked online through our secured website, by email or by phone.</p>
                                    <ul>
                                        <li>10% of the total rental price displayed online is required to secure a booking with OLA Mauritius. Remaining 90% is to be paid upon delivery at the rental desk in either CC ( or cash. To be noted that online offers are the best rate.</li>
                                        <li>Email reservation may be done at
                                            <a className="text-secondary" href="mailto:info@olamauritius.com"> info@olamauritius.com</a>.
                                            However the charges will be normally higher than online offers. Full payment will be required prior to your reservation date.
                                        </li>
                                        <li>Phone reservation is also possible on +23057900575 on Week days to Saturdays between 9 am to 4 pm. Full payment will be processed prior to delivering the car at any locations.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <h3>3. VEHICLE: Cars group/model,Inspection,1Use</h3>
                                    <h4 className="text-secondary">3.1 Cars groups and models</h4>
                                    <ul>
                                        <li>We cannot guarantee any particular make or model that has been booked by the Renter. &nbsp;Cars on display and listed are the most commonly used but may be substituted at our discretion for planning purposes or by any unforeseen circumstances such as mechanical breakdown or accidents. &nbsp;Alternately same category of cars will be offered in terms of engine capacity, transmission mode and boot space.</li>
                                        <li>In the event we are unable to provide same category of car or any upgraded option (without any surcharge) we’ll&nbsp;issue a complete refund of the initial deposit.</li>
                                        <li>In case we have no other option than to provide a downgrade category of vehicle with your approval prices will be revised and adjusted accordingly.</li>
                                        <li>OLA (MTIUS) Co Ltd excludes any liability of any additional charges, compensation or any direct or indirect loss resulting from any such changes.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <h4 className="text-secondary">3.2 Inspection</h4>
                                    <ul>
                                        <li>A joint pre hire inspection shall be carried out between the ” Renter” and our agent before taking charge and full responsibility of the vehicle. All apparent defects and fuel level shall be listed and signed by both parties.</li>
                                        <li>A post inspection shall also be done upon drop off of the rented vehicle in the presence of our agent. &nbsp;The post inspection form must be duly signed by both parties to avoid any conflictual issues, which might arise later. &nbsp;It is the responsibility of the Renter to sign the post inspection form failing which the Renter shall bear any further charges that may be billed to him at a later stage.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <h4 className="text-secondary">3.3 Use of Vehicle</h4>
                                    <ul>
                                        <li>The Renter shall make use of the vehicle responsibly with due care and attention and maintain its good working condition.</li>
                                        <li>The Renter shall also be responsible to any additional devices such as GPS, car seat and return same in the initial condition. Additional extras are not covered by insurance.</li>
                                        <li>The Vehicle shall at all times be locked when left unattended. Subsequently any loss or damage caused to an unlocked Vehicle shall be solely the Renter’s responsibility.</li>
                                        <li>The Vehicle is delivered spotlessly clean and shall be returned as far as practicable in the same condition of neatness. A cleaning fee might be charged to the Renter in cases where the vehicle is returned in advance messy condition.</li>
                                        <li>The Renter shall abide to the Road Traffic Act in force in Mauritius and shall be liable to any fines resulting in any breach accordingly during the rental period.</li>
                                        <li>The Vehicle shall be returned in the same condition as at the commencement of the rental and the Renter shall be liable to reimburse OLA Rent A Car Ltd for any damage(s) caused to the Vehicle, and refund OLA Rent A Car Ltd for any costs it incurs.</li>
                                    </ul>
                                    <h4 className="text-secondary">3.4 Maintenance / Mechanical Problems</h4>
                                    <ul>
                                        <li>All maintenances and mechanical repairs are undertaken by OLA (MTIUS) Co Ltd unless or otherwise with the written approval of OLA(MTIUS) Co Ltd. The fees and expenses of any repair(s) undertaken without any written consent will not be reimbursed to the Renter.</li>
                                        <li>All vehicles are rented in a roadworthy condition. However the Renter must immediately stop the vehicle in the case of any abnormality indicated by the instrument panel warning lights or any other reason the renter believes any mechanical problems could arise. Renter shall at once inform OLA (MTIUS) Co Ltd of any such issues.</li>
                                    </ul>
                                    <h4 className="text-secondary">3.5 Roadside Assistance</h4>
                                    <ul>
                                        <li>During the hiring period the Renter is entitled to a free 24 hrs roadside assistance in cases of <strong>mechanical breakdown</strong>&nbsp;only or accident. However in cases of negligence the Renter shall incur an extra fee.</li>
                                        <li>In cases of dead battery by negligence an extra fee of 600 MUR will be charged to the Renter.</li>
                                        <li>OLA (MTIUS) Co Ltd reserves the right to provide a replacement car in cases of road accident.</li>
                                    </ul>
                                    <h3>4. Rental Period</h3>
                                    <ul>
                                        <li>Renter shall return the Vehicle at the agreed place, date and time mentioned in the rental agreement.</li>
                                        <li>Extension of the hiring period shall be notified to OLA(MTIUS) Co Ltd at least 2 days before the end of the rental date.</li>
                                        <li>The rental rate is calculated on a daily plus an extra hourly basis. A 30 minutes grace is normally applied at the end of the hiring period after which the Renter shall be charged for one additional day.</li>
                                        <li>Renter shall return the vehicle at the time specified on the rental agreement failing which additional charges will be applied and may be higher than normal rates.</li>
                                    </ul>
                                    <h3>5. ACCIDENT</h3>
                                    <ul>
                                        <li>In the event of an accident Renter shall immediately inform OLA (MTIUS) Co Ltd and seek guidance and advice.</li>
                                        <li>In the Mauritian context where accident occurs and does not result in bodily harm and where there is not more than two vehicles involved parties shall mutually proceed to an agreed statements of facts.</li>
                                        <li>No insurance ( normal or extra insurance) will cover damages or repairs cost in the event where renter has failed to submit the ” Agreed Statements of Facts” form.</li>
                                        <li>In cases of complexity the OLA Emergency team will intervene within the least possible delay and take charge of the situation.</li>
                                        <li>In the event the vehicle becomes un-roadworthy resulting from an accident OLA (MTIUS) Co Ltd reserves the right to replace the car within the same category . If replacement cars is not available for any other reasons, &nbsp;OLA (MTIUS) Co Ltd reserves the right to cancel the booking and will refund the car rental amount for any unused days as from the date of the accident.</li>
                                    </ul>
                                    <h3>6. Terms of Payment</h3>
                                    <ul>
                                        <li>A 25% of the total rental price is required to have a firm reservation from OLA (MTIUS) Co Ltd.</li>
                                        <li>Remaining balance shall be effected at the time of delivery by the Renter in either Cash (Euro, USD or MUR) or by Credit Card. However a 5% commission shall apply in addition to the total rental fee as bank charges if payment is done by credit card. To be also noted &nbsp;that a selling rate will be apply for conversion into MUR.</li>
                                    </ul>
                                    <h3>7. Cancellation &amp; Refunds</h3>
                                    <ul>
                                        <li>What is the cancellation policy for fully prepaid or part-paid bookings?
                                            <ul>
                                                <li>If you cancel up to 7 working days prior to your pick up, you will be refunded in full. If you cancel less than 5 working days no refund will be accepted.</li>
                                                <li>If your online payment is less than €50, no refund will be made for your car hire booking.</li>
                                                <li>If your booking is made less than 72 hours before pick up, the cancellation policy will automatically apply to your booking.</li>
                                                <li>All cancellations should be notified by email at info@olamauritius.com</li>
                                                <li>Amending your booking or cancelling optional extras may not be possible less than 24 hours before pick up.</li>
                                                <li>No refund will be accepted for email or phone reservation for last minute cancellation.</li>
                                            </ul>
                                        </li>
                                        <li>There will be no refunds on unused days of rental or for early return.</li>
                                        <li>Hirer shall bear all administration or commission fees for all refunds by bank transfers or Credit card .</li>
                                    </ul>
                                    <h3>8. Insurance</h3>
                                    <ul>
                                        <li>All rentals at OLA Mauritius come along with an ”All Comprehensive Insurance Cover” which include CDW (Collision Damage Waiver ) , Third Party &nbsp;and TP (Theft Protection) with a deductible or excess varying from 500 to 1000 Euros depending on the car models or categories. You therefore limit your financial liability to the deductible or excess amount. &nbsp;However you’ll not be covered by our insurance in cases of drunken driving or gross negligence. To be noted that we will block the excess amount on your credit card as a deposit. If you return the vehicle undamaged, the deposit will be released immediately.</li>
                                        <li>Please see below deductible /excess amount for different car categories :</li>
                                        <li>Mini € 500</li>
                                        <li>Economy &amp; Family € 500</li>
                                        <li>MPV € 600</li>
                                        <li>&nbsp;Pick up&nbsp; € 1000</li>
                                        <li>Luxury SUV € 1500</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>Insurance waivers or extra insurance :</li>
                                    </ul>
                                    <p>At OLA you can also opt to reduce the excess to half by paying an additional daily rate to your normal rental charges but &nbsp; &nbsp; &nbsp; &nbsp; which is non refundable(Applies for a minimum of 5 days rental) . This simply means that if any damage occurs to your rented vehicle you won’t be liable to pay the total deductible or excess as you already chose to go for an extra insurance. To be noted that we’ll block half the excess amount on your credit card. However extra insurance excludes the following :</p>
                                    <ul>
                                        <li>Accidental damage to windows, tyres, undercarriage, exterior mirrors, headlights and roof</li>
                                        <li>Loss of keys</li>
                                        <li>Loss of personal belongings</li>
                                        <li>Loss for extras like GPS and car seats</li>
                                        <li>In cases of drunken driving</li>
                                    </ul>
                                    <h3>9. Liability</h3>
                                    <ul>
                                        <li>Renter or any additional driver shall be liable to any fines or contraventions under the Road Traffic Act during the hiring period.</li>
                                        <li>Any fines or penalties resulting from a breach of the Road Traffic Act shall be billed to the Renters credit card when the rented vehicle was under his sole responsibility. &nbsp;It must be noted that OLA (MTIUS) Co Ltd might be served with any kind of summons or notice from the Mauritius Police for any breach of the traffic rules in force even after Renter departed from the Jurisdiction of Mauritius. Such fines will be billed to the Renter and failure to settle any such fines will be reported to the Police and Renter might be refused entry in Mauritius for any forthcoming visit at the Immigration desk.</li>
                                        <li>Renter shall be accountable to any damage(s) if the vehicle is returned in a condition other than listed during the joint pre hire inspection process.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                </article>
                            </section>
                        </div>
                    </div>
            }


        </div>
    </div >);
}

export default Terms;