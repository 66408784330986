import { Outlet } from "react-router-dom"
import PriceCalculator from "./priceCalculator"
import { useState, useEffect } from "react"

const Price = () => {
    
    const [products, setProducts] = useState({})
    const [fetchedData, setFetchedData] = useState({})
    const [checkoutData, setCheckoutData] = useState({finalPrice: 0, bookDisabled: false})
    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth >= 992)

    const contextValue = {
      products: [products, setProducts], 
      checkoutData: [checkoutData, setCheckoutData], 
      fetchedData:[fetchedData, setFetchedData]
    }

    useEffect(() => {
        const handleResize = () => {
            setIsBigScreen(window.innerWidth >= 992);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
    <>
        {isBigScreen && <PriceCalculator products={products} checkoutData={checkoutData}/>}
        <Outlet context={contextValue}/>
        {!isBigScreen && <PriceCalculator products={products} checkoutData={checkoutData}/>}
    </>
    )
}

export default Price