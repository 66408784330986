import { useRef, useState } from "react";
import { useParams } from "react-router-dom";

const ProductCards = ({option, duration, setProducts}) => {

    const [productAmount, setProductAmount] = useState(0)
    const [optionChecked, setOptionChecked] = useState(false)
    const addBtnRef = useRef()
    const quantityContainerRef = useRef()
    const params = useParams()

    const changeQuantity = (optionId, optionType, screenSize,  mathOperation,) => {
        console.log(optionId, optionType);
        const number = mathOperation === 'sum' ? 1 : -1
        console.log(number);
        if(screenSize === 'mobile'){
            if (optionType.toLowerCase() === "multi" && ((mathOperation === 'sum' && productAmount < 4) || (!mathOperation && productAmount > 1))) {
                setProducts((prevState => {
                    return {
                        ...prevState,
                        [optionId]: {product: option, amount: prevState[optionId]['amount']+number}
                        
                    }
                }))
                setProductAmount(prevState => prevState+number)
            }
        }
        else{
            if (optionType.toLowerCase() === "multi" && ((mathOperation === 'sum' && productAmount < 4) || (!mathOperation && productAmount > 0))) {
                setProducts((prevState => {
                    return {
                        ...prevState,
                        [optionId]: {product: option, amount: prevState[optionId]['amount']+number}
                        
                    }
                }))
                setProductAmount(prevState => prevState+number)
            }

            if(optionType.toLowerCase() !== "multi" && !mathOperation && productAmount === 1){
                onSwitchCheckHandler(false, optionId)
            }

            if(!mathOperation && productAmount === 1){
                addBtnRef.current.classList.remove("d-none");
                quantityContainerRef.current.classList.add("d-none");
                setOptionChecked(false)
            }
        }
    }

    const toggleAddBtn = (optionId, option) => {
        addBtnRef.current.classList.add("d-none");

        setOptionChecked(true)
        setProductAmount(1)
        setProducts((prevState => {
            return {
                ...prevState,
                [optionId]: {product: option, amount: 1}
            }
        }))

        quantityContainerRef.current.classList.remove("d-none");
    }

    const onSwitchCheckHandler = (checked, optionId) => {
        setOptionChecked(checked)
        if(checked){
            setProductAmount(1)
            setProducts((prevState => {
                return {
                    ...prevState,
                    [optionId]: {product: option, amount: 1}
                }
            }))
        }
        else{
            setProductAmount(0)
            setProducts((prevState => {
                return {
                    ...prevState,
                    [optionId]: {product: option, amount: 0}
                }
            }))
        }
    }

    let imgSrc
    switch(option.name.trim()){
        case 'Additional driver':
            imgSrc = '../../../images/foreign-use.webp'
            break;
        case 'Mobile Wi-Fi':
            imgSrc = '../../../images/Wi-Fi.webp'
            break;
        case 'Roadside Assistance Plus':
            imgSrc = '../../../images/circle_plus.webp'
            break;
        case 'Baby seat (0-13 kg)':
        case 'Child Seat (9-18 kg)':
        case 'Booster Seat (15-25 kg)':
            imgSrc = '../../../images/ico_childseat.webp'
            break;
        default: imgSrc = ''
    }
    // BE supprorts only "fr" and "en" for now
    let shortDescription = params.lang == "fr" ? option.shortDescriptionFr : option.shortDescriptionEn;

    return  (
        <div key={"key-options-" + option.id} id={"option-card-id-" + option.id} className='col-12 card card__booking-options'>
            <div className="card-body">
                <div className="row row--option-details align-items-center">
                    {option.sticker?.toLowerCase() == "recommended" ?
                        <div className="col-12">
                            <div className="car-status car-status__best-deal ms-auto mb-3">
                                <i className="bi bi-star-fill"></i>
                                <span>Recommended for you</span>
                            </div>
                        </div>
                        : ""
                    }
                    <div className="col-lg-11 accordion-item">
                        <div className="d-xl-none">
                            <div className='row align-items-start'>
                                <div className='col-2 pt-2'>
                                    <img
                                        className="me-3 accordion__icon"
                                        src={imgSrc}
                                        alt="option icon" width={30} height={30}
                                    />
                                </div>
                                <div className='col-7 ps-0'>
                                    <div className="col-lg-6 card-title semi-bold">{option.name}</div>
                                    <div className="col-6 card-price semi-bold">
                                        {
                                            option.firstFree.toLowerCase() === "yes" ?
                                                "Included with reservation"
                                                :
                                                option.calculate.toLowerCase() === "day" ?
                                                    `€ ${Math.round(option.price / duration).toFixed(2)} / day` :
                                                    `€ ${Math.round(option.price).toFixed(2)}`
                                        }
                                    </div>
                                    <button
                                        className="btn accordion-button text-secondary collapsed d-block text-center ps-0"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={"#accordion-option-" + option.id}
                                        aria-expanded="false"
                                        aria-controls={"accordion-option-" + option.id}
                                        disabled={!shortDescription}
                                    >
                                        Details
                                    </button>
                                </div>
                                <div className="col-3 p-0 mt-2 d-flex flex-column gap-3 align-items-center">                                
                                    <div className='switch__container'>
                                        <label className='switch__label'>
                                            <input type='checkbox' onClick={(e)=>onSwitchCheckHandler(e.target.checked, option.id)}/>
                                            <span className='switch product__switch'></span>
                                        </label>
                                    </div>
                                    {
                                    optionChecked && 
                                    <div id={"option-quantity-id-" + option.id} className="container-quantity pe-2">
                                        <div className="">
                                            <label className="text-center w-100 semi-bold">Quantity</label>
                                            <div className="quantity d-flex">
                                                <button className="quantity__minus"
                                                    onClick={() => changeQuantity(option.id, option.type, 'mobile')}
                                                ><h4 className="m-0 p-0">-</h4></button>
                                                <input
                                                    id={"option-input-id-" + option.id}
                                                    name="quantity"
                                                    className="quantity__input"
                                                    value={productAmount}
                                                    readOnly
                                                />
                                                <button
                                                    className="quantity__plus"
                                                    onClick={() => changeQuantity(option.id, option.type, 'mobile', 'sum')}
                                                ><h4 className="m-0 p-0">+</h4>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className='row align-items-center '>
                                <div className="col-8 accordion-header ps-0" id={"accordion-option-" + option.id + "-heading"}>

                                </div>
                            </div>

                        </div>
                        <div className="row align-items-center d-none d-xl-flex">
                            <div className="col-lg-6 card-title semi-bold">{option.name}</div>
                            <div className="col-lg-3 card-price semi-bold">
                                {
                                    option.firstFree.toLowerCase() === "yes" ?
                                        "Included with reservation"
                                        :
                                        option.calculate.toLowerCase() === "day" ?
                                            `€ ${Math.round(option.price / duration).toFixed(2)} / day` :
                                            `€ ${Math.round(option.price).toFixed(2)}`
                                }
                            </div>

                            <div className="col-lg-3 accordion-header d-none d-xl-block" id={"accordion-option-" + option.id + "-heading"}>
                                <button
                                    className="btn accordion-button text-secondary collapsed d-block text-center"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#accordion-option-" + option.id}
                                    aria-expanded="false"
                                    aria-controls={"accordion-option-" + option.id}
                                    disabled={!shortDescription}
                                >
                                    Details
                                </button>

                            </div>
                        </div>
                        <div className="row">

                            <div className="col-12 pe-2 pe-lg-3">
                                <div
                                    id={"accordion-option-" + option.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={"accordion-option-" + option.id + "-heading"}
                                    data-bs-parent="#accordion-options"
                                >
                                    <div className="accordion-body d-flex align-items-center p-0 p-xl-3">
                                        <img
                                            className="my-2 me-3 accordion__icon d-none d-xl-block"
                                            src={imgSrc}
                                            alt="option icon" width={30} height={30}
                                        />
                                        <div className="ms-md-0 accordion__description">
                                            {shortDescription}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>                            
                    <div className="col-lg-1 p-0 mt-4 mt-lg-0 d-none d-xl-block">
                        <button
                            ref={addBtnRef}
                            id={"option-add-btn-id-" + option.id}
                            className="btn btn-outline-secondary  w-100 px-2 semi-bold"
                            onClick={() => toggleAddBtn(option.id, option)}
                        >
                            + Add
                        </button>
                        <div id={"option-quantity-id-" + option.id} className="container-quantity d-none" ref={quantityContainerRef}>
                            <div className="">
                                <label className="text-center w-100 semi-bold">Quantity</label>
                                <div className="quantity d-flex">
                                    <button className="quantity__minus"
                                        onClick={(e) => changeQuantity(option.id, option.type, 'desktop')}
                                    ><h4 className="m-0 p-0">-</h4></button>
                                    <input
                                        id={"option-input-id-" + option.id}
                                        name="quantity"
                                        className="quantity__input"
                                        value={productAmount}
                                        readOnly
                                    />
                                    <button
                                        className="quantity__plus"
                                        onClick={(e) => changeQuantity(option.id, option.type, 'desktop', 'sum')}
                                    ><h4 className="m-0 p-0">+</h4>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCards