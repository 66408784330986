import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import lang from "../../lang/lang.json";

function Contact() {
    const params = useParams();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    return (<div className="ola-old-pages">
        <section className="internal-header">

            <div className="container">
                <div className="row v-align-children">
                    <div className="col-md-8">
                        <h1>
                            {lang[params.lang]?.footer.contactUs || "Contact us"}
                        </h1>
                    </div>

                    <div className="col-md-4 d-none d-md-block">
                        <ol className="breadcrumb float-end">
                            <li>
                                <a href={params.lang ? `/${params.lang}/` : `en/`}>
                                    {
                                        params.lang == "fr" ?
                                            "Accueil"
                                            :
                                            "Home"
                                    }
                                </a>
                            </li>
                            <li className="current">
                                {lang[params.lang]?.footer.contactUs || "Contact us"}
                            </li>
                        </ol>
                    </div>

                </div>
            </div>
        </section>


        <div className="container mb-4">
            <div className="row">
                <div className="col-lg-8">
                    <section>

                        {
                            params.lang == "fr" ?
                                <>
                                    <div className="alert alert-warning" role="alert">
                                        {/* <a href="#" className="close" data-dismiss="alert" aria-label="close" title="close">×</a> */}
                                        Bénéficiez 10% de remise pour une <a href="/fr/"><strong><u>réservation en ligne</u></strong></a> . C’est simple facile et moins chère qu’une réservation par mail.
                                    </div>
                                    <p>Envoyez-nous un message en remplissant le formulaire ci-dessous:</p>
                                </>
                                :
                                <>
                                    <div className="alert alert-warning" role="alert">
                                        {/* <a href="#" className="close" data-dismiss="alert" aria-label="close" title="close">×</a> */}
                                        <a href="/en/"><strong><u>Book online</u></strong></a> instead of email reservation and save up to 10% on your overall price. Booking online is simple, fast and secure.
                                    </div>
                                    <p>Send us a message by completing the form below:</p>
                                </>
                        }
                        <div id="contact">
                            <form id="contact-form" onSubmit={() => { }} className="w-100">
                                <div className="row">
                                    <div className="col-lg-4 form-group">
                                        {
                                            params.lang == "fr" ?
                                                <label className="mt-3 mb-2">NOMS*</label>
                                                :
                                                <label className="mt-3 mb-2">Full Name*</label>
                                        }
                                        <input
                                            name="name"
                                            className="form-control input-field"
                                            type="text"
                                            value={fullName} onChange={(e) => setFullName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        {
                                            params.lang == "fr" ?
                                                <label className="mt-3 mb-2">ADDRESSE EMAIL*</label>
                                                :
                                                <label className="mt-3 mb-2">Email Address*</label>
                                        }
                                        <input
                                            name="email"
                                            className="form-control input-field"
                                            type="email"
                                            value={email} onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        {
                                            params.lang == "fr" ?
                                                <label className="mt-3 mb-2">NUMÉRO DE TÉLÉPHONE*</label>
                                                :
                                                <label className="mt-3 mb-2">Phone Number*</label>
                                        }
                                        <input
                                            name="phone"
                                            className="form-control input-field"
                                            type="text"
                                            value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 form-group">
                                        {
                                            params.lang == "fr" ?
                                                <label className="mt-3 mb-2">MESSAGE*</label>
                                                :
                                                <label className="mt-3 mb-2">Message*</label>
                                        }
                                        <textarea
                                            name="message"
                                            className="form-control input-field"
                                            rows="6"
                                            value={message} onChange={(e) => setMessage(e.target.value)}
                                            aria-required="true"
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="row justify-content-end">

                                    <div className="col-12 col-lg-4">
                                        <div className="mt-4 mb-5">

                                            {
                                                params.lang == "fr" ?
                                                    <input value="Envoyez Message" type="submit" className="btn btn-primary fw-bold w-100" />
                                                    :
                                                    <input value="Send Message" type="submit" className="btn btn-primary fw-bold w-100" />
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="form-group col-12 text-end mt-4 mb-5">
                                        {
                                            params.lang == "fr" ?
                                                <input value="Envoyez Message" type="submit" className="btn btn-primary fw-bold ms-auto" />
                                                :
                                                <input value="Send Message" type="submit" className="btn btn-primary fw-bold ms-auto" />
                                        }
                                    </div> */}
                                </div>
                            </form>

                        </div>
                    </section>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <section className="bg-secondary p-4">
                        <div>
                            <img src="../../../images/logo.webp" width="150" alt="Ola logo" />
                            <div className="py-2">


                                <p><strong>SSR International Airport</strong>,<br />
                                    742CU001, Plaine Magnien,<br />
                                    Mauritius</p>
                                <p>

                                    {
                                        params.lang == "fr" ?
                                            <abbr title="Service Clients">Service Clients:</abbr>
                                            :
                                            <abbr title="Customer Service">Customer Service:</abbr>
                                    }
                                    <a href="tel:+230 5790 0575" title="Customer Service">+230 5790 0575</a><br />


                                    {
                                        params.lang == "fr" ?
                                            <abbr title="Assistance">Assistance:</abbr>
                                            :
                                            <abbr title="Helpdesk">Helpdesk:</abbr>
                                    }
                                    <a href="tel:+230 5254 0050" title="Helpdesk">+230 5254 0050</a><br />
                                    <abbr title="Reservation">Reservation:</abbr> <a href="tel:+230 627 1929" title="Reservation">+230 627 1929</a><br />

                                    {
                                        params.lang == "fr" ?
                                            <abbr title="Address Email">Address Email:</abbr>
                                            :
                                            <abbr title="Email">Email:</abbr>
                                    }
                                    <a href="mailto:info@olamauritius.com"> info@olamauritius.com</a></p>


                            </div>
                        </div>
                        <div className="intouch text-center">
                            {
                                params.lang == "fr" ?
                                    <h4>SUIVEZ NOUS:</h4>
                                    :
                                    <h4>Keep in touch:</h4>
                            }
                            <div className="d-flex gap-3 justify-content-center mt-2">
                                <a href="http://www.facebook.com/olamauritius" target="_blank">
                                    <i className="bi bi-facebook"></i>
                                </a>
                                <a href="http://www.twitter.com/olamauritius/" target="_blank">
                                    <i className="bi bi-twitter-x"></i>
                                </a>
                                <a href="https://www.instagram.com/olamauritius/" target="_blank">
                                    <i className="bi bi-instagram"></i>
                                </a>
                            </div>

                            {/* <a href="http://www.facebook.com/olamauritius"><i className="icon-facebook"></i></a>
                            <a href="http://www.twitter.com/olamauritius/"><i className="icon-twitter"></i></a>
                            <a href="https://plus.google.com/b/114242511056019862963/"><i className="icon-gplus"></i></a>
                            <a href="https://www.linkedin.com/company/ola-mauritius-car-rental"><i className="icon-linkedin"></i></a> */}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>);
}

export default Contact;