import React, { useState, useEffect } from 'react';
import { useSearchParams, Link, useParams } from "react-router-dom";
import DOMPurify from 'dompurify'

import { formatDate } from '../../utils/dateUtils';
import Posts from '../../components/Posts';
import { getBlog } from '../../services/apiBlogsService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function BlogDetails() {
    const params = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [showBlogPlaceholder, setshowBlogPlaceholder] = useState(true);
    const [blog, setBlog] = useState(false);
    const [placeholder, setPlaceholder] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const routeId = searchParams.get("id");

    useEffect(() => {
        console.log("searchParams: ", searchParams)
        getPlaceholders();
        // if (!blog) {
            // Timeout needs to mimic asynchrone blog loading
            setTimeout(() => {
                getBlogDetails();
            }, 300);
            window.scrollTo(0, 0);
        // }
    }, [searchParams]);

    let getBlogDetails = async () => {
        setshowBlogPlaceholder(true);
        let result = await getBlog(axiosPrivate, routeId, params.lang);
        console.log("blog: ", result)
        // result = result?.data

        let blogSections;

        if (result.inner_html?.length > 0) {
            blogSections = <div dangerouslySetInnerHTML={
                { __html: DOMPurify.sanitize(result.inner_html) }
            } />
        } else {
            blogSections = []
            if (result && result.sections.length > 0) {
                for (let i = 0; i < result.sections.length; i++) {
                    let blogSectionParagraphs = [];
                    for (let p = 0; p < result.sections.length; p++) {
                        blogSectionParagraphs.push(
                            <p>
                                {result.sections[i].text[p]}
                            </p>
                        );
                    }
                    console.log("{result.sections[i]", result.sections[i])
                    blogSections.push(
                        <div>
                            <h2 className="blog-section-titlе semi-bold">
                                {result.sections[i].title}
                            </h2>
                            <div className="blog-section-text">
                                {blogSectionParagraphs}
                            </div>
                        </div>
                    );
                }
            }
        }

        let blogTemp =
            <div className="blog">
                <div className="hero">
                    <div className="container hero-text-container p-3 p-md-5">
                        <div className="blog-time semi-bold">
                            {result.timestamp && formatDate(new Date(result.timestamp), "DD MMM, YYYY")}
                        </div>
                        <h1 className="blog-title semi-bold">{result.title}</h1>
                    </div>

                    <div className="hero-image" aria-hidden="true">
                        <img src={result.imgUrl} alt={result.imgAlt || result.title} aria-hidden="true" />
                    </div>
                </div>


                {/* <div className="img-wrp">
                    <img className="blog-img" src={result.imgUrl} alt="Car parking" />
                    <div className="blog-time">{result.timestamp}</div>
                    <h1 className="blog-title">{result.title}</h1>
                    <div className="blog-author">{result.title}</div>
                </div> */}
                <div className="blog-body mx-auto px-5">
                    {blogSections}
                </div>
            </div>

        setBlog(blogTemp);

        setshowBlogPlaceholder(false);
    }

    let getPlaceholders = async () => {
        let placeholderTemp = [];
        for (let i = 0; i < 3; i++) {
            placeholderTemp.push(
                <div className="blog blog--plcaseholder">
                    <div className="card-img-top">
                        <div className="load-wraper">
                            <div className="activity"></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">
                            <div className="load-wraper">
                                <div className="activity"></div>
                            </div></h5>
                        <div className="card-text">
                            <div className="load-wraper">
                                <div className="activity"></div>
                            </div></div>
                        <div className="btn"></div>
                    </div>
                </div>
            );
        }

        if (placeholderTemp.length > 0) {
            setPlaceholder(placeholderTemp);
        }
    }

    return (
        <section className="blog-section">
            <div className="pb-5">
                {!!showBlogPlaceholder ?
                    <>
                        {placeholder}
                    </>
                    :
                    <>
                        {blog}
                    </>
                }
            </div>

            <Posts />

        </section>
    );
}

export default BlogDetails;