// const API_URL = process.env.REACT_APP_API;

async function getAllLocations(axiosPrivate) {
    // let results = axiosPrivate.post(
    //     `${API_URL}/locations`,
    //     {}
    // );

    // return results;

    return {
        data: [
            {
                title: "Grand Baie Rental Cars 1",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=top-10-night-clubs-in-mauritius"
            },
            {
                title: "Grand Baie Rental Cars 2",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=the-top-10-restaurants-in-mauritius"
            },
            {
                title: "Grand Baie Rental Cars 3",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=planning-a-mauritius-honeymoon-a-complete-guide"
            },
            {
                title: "Grand Baie Rental Cars 4",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.'
            },
            {
                title: "Grand Baie Rental Cars 5",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.'
            },
            {
                title: "Grand Baie Rental Cars 6",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.'
            }
        ]
    }
}

export {
    getAllLocations
}