import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

function Faq() {
    const params = useParams();

    return (<div className="ola-old-pages">
        <section className="internal-header">

            <div className="container">
                <div className="row v-align-children">
                    <div className="col-md-8 col-sm-12">
                        <h1>
                            FAQ’s
                        </h1>
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <ol className="breadcrumb float-end">
                            <li>
                                <a href={params.lang ? `/${params.lang}/` : `en/`}>
                                    {
                                        params.lang == "fr" ?
                                            "Accueil"
                                            :
                                            "Home"
                                    }
                                </a>
                            </li>
                            <li className="current">FAQ’s</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <div className="container mt-2 mb-5">
            <div className="row">
                <div className="col-md-8">
                    <section className="text-box">
                        <article>
                            <p>We’ve put together this list of freqently asked questions to assist you any questions you might have.</p>
                            <div className="accordion accordion__list" id="accordion-faq">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accordion-faq-1-heading">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-1" aria-expanded="true" aria-controls="accordion-faq-1">
                                            Insurance
                                        </button>
                                    </h2>
                                    <div id="accordion-faq-1" className=" accordion-collapse collapse show" aria-labelledby="accordion-faq-1-heading" data-bs-parent="#accordion-faq">
                                        <div className="accordion-body text-black">
                                            <h3 className="text-secondary">What is your insurance policy?</h3>
                                            <p>All rentals at OLA Mauritius come along with an ”All Comprehensive Insurance Cover” which include CDW (Collision Damage Waiver ) and TP (Theft Protection) with a deductible or excess varying from 500 to 700 Euros depending on the car models or categories. You therefore limit your financial liability to the deductible or excess amount. &nbsp;However you’ll not be covered by our insurance in cases of drunken driving or gross negligence. To be noted that we will block the excess amount on your credit card as a deposit. If you return the vehicle undamaged, the deposit will be released immediately.<br />
                                                Please see below deductible /excess amount for different car categories :</p>
                                            <p>Mini : € 500<br />
                                                Economy &amp; Family : € 500<br />
                                                MPV :&nbsp;€ 700<br />
                                                Pick up : € 1000</p>
                                            <p>Luxury SUV : € 1500</p>
                                            <p>Insurance waivers or extra insurance :</p>
                                            <p>At OLA you can also opt to reduce the excess to half by paying an additional daily rate to your normal rental charges but which is non refundable(Applies for a minimum of 5 days rental) . This simply means that if any damage occurs to your rented vehicle you won’t be liable to pay the total deductible or excess as you already chose to go for an extra insurance. To be noted that we’ll block half the excess amount on your credit card. However extra insurance excludes the following :</p>
                                            <ul>
                                                <li>Loss of keys</li>
                                                <li>Loss of personal belongings</li>
                                                <li>Loss for extras like GPS and car seats</li>
                                                <li>In cases of drunken driving and careless driving</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accordion-faq-2-heading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-2" aria-expanded="false" aria-controls="accordion-faq-2">
                                            Online Booking FAQs
                                        </button>
                                    </h2>
                                    <div id="accordion-faq-2" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-2-heading" data-bs-parent="#accordion-faq">
                                        <div className="accordion-body text-black">
                                            <h3 className="text-secondary">How do the online booking works?</h3>
                                            <p>Booking a car with OLA Mauritius takes less than 3 minutes! Our booking engine is very simple and easy to use. You just need to select details like pick up &amp; drop off location, dates and time. You will then be able to view available vehicles in real time together with our best quote and detailed features of each vehicle. In the last step you will need to provide your particulars and other details before paying a deposit of 25% of the total rental price to guarantee your reservation.The 75% has to be settled upon delivery either by cash or credit card(3% bank charges will be applied for credit card transactions). Conversions will be applied at a selling rate.</p>
                                            <h3 className="text-secondary">Is it safe to pay online?</h3>
                                            <p>Yes. &nbsp;Our payment gateway provides the highest level of certification possible (The Payment Card Industry Data Security Standard-PCI Level 1 compliance). &nbsp;This means that all your transactions are protected from online threats and are subject to rigorous measures which &nbsp;help &nbsp;to mitigate fraud from impacting your operations. &nbsp;Our payment gateway further uses statistical algorithms and data collection techniques to assess&nbsp;more than 300 variables in under 3 seconds to identify markers of fraud.</p>
                                            <h3 className="text-secondary">What happen when I complete my deposit payment?</h3>
                                            <p>You will receive a confirmation voucher within minutes with all the rental details on the email address you provided.</p>
                                            <h3 className="text-secondary">Do I have to pay any additional taxes or charges on the quoted price?</h3>
                                            <p>No, our quoted price is inclusive of all taxes and charges. You pay what you see!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accordion-faq-3-heading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-3" aria-expanded="false" aria-controls="accordion-faq-3">
                                            Car Rental FAQs
                                        </button>
                                    </h2>
                                    <div id="accordion-faq-3" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-3-heading" data-bs-parent="#accordion-faq">
                                        <div className="accordion-body text-black">
                                            <h3 className="text-secondary">Who can rent a vehicle?</h3>
                                            <p>Anyone who proves his competency as driver by producing a valid driving licence issued at least 2 years prior to the hire date.</p>
                                            <h3 className="text-secondary">Is there a minimum rental period?</h3>
                                            <p>Yes.The minimum rental period is 3 days.</p>
                                            <h3 className="text-secondary">How can I make a booking?</h3>
                                            <p>You can use our easy and secure online booking engine, by email, phone, Live chat or on Skype. A 25% of the total rental fee is required to confirm a booking. A reservation voucher will be sent via email after the deposit payment with all details.</p>
                                            <h3 className="text-secondary">What documents do I need to produce on delivery?</h3>
                                            <ul>
                                                <li>Driving Licence</li>
                                                <li>Passport or ID</li>
                                                <li>Valid Credit Card</li>
                                                <li>Reservation&nbsp; voucher</li>
                                            </ul>
                                            <h3 className="text-secondary">How can I pay for the rental fee?</h3>
                                            <p>OLA Mauritius accepts Cash, MasterCard,Visa &amp; PayPal.</p>
                                            <h3 className="text-secondary">How can I include an additional driver?</h3>
                                            <p>At the time of delivery the additional driver will be added in the rental agreement at no extra cost.</p>
                                            <h3 className="text-secondary">What type of Insurance cover OLA Mauritius provides?</h3>
                                            <p>We offer an all comprehensive insurance policy which includes Collision Damage Waiver (CDW) and Theft Protection (TP) on all our vehicles with a deductible of 500 to 1000 euros depending on the car model or category.</p>
                                            <h3 className="text-secondary">Do I need to pay any Excess cover?</h3>
                                            <p>Yes. You need to make a deposit excess/deductible of 500-1000 euros(depending on the car model or category) upon delivery which is refundable if the vehicle is returned in the same condition. This can be done either by cash or by preauthorisation from your credit card. Note that the excess amount from your credit card will not be debited. However you will be able to waive the excess/deductible to zero against a non refundable fee of 6-15 euros per day(only for rentals of more than 6 days)</p>
                                            <h3 className="text-secondary">How does the All Comprehensive Insurance Cover works?</h3>
                                            <p>In cases of accident an evaluation is carried out as to the damages caused. You will be requested to pay only the excess amount if damages exceed the excess cover as the insurance company will take charge for any additional cost. In cases where the damage is below the excess amount we’ll simply request you to pay that amount.</p>
                                            <h3 className="text-secondary">Where will I find OLA Mauritius staff once I land in Mauritius?</h3>
                                            <p>Our staff will be greeting you at the arrival lounge &nbsp;Gate 6 with a board bearing ” OLA Mauritius”.</p>
                                            <h3 className="text-secondary">How can I contact OLA Mauritius when I arrive in Mauritius?</h3>
                                            <p>You can contact us on +23057900575 ( Normal line or WhatsApp) . All details will be provided in your reservation voucher.</p>
                                            <h3 className="text-secondary">What will happen if my flight is delayed?</h3>
                                            <p>Simply don’t worry we will wait for you. &nbsp;Please notify us by WhatsApp or email ( hello@olamauritius.com)</p>
                                            <h3 className="text-secondary">What if I am traveling with lots of luggage.Is my rented car spacious enough ?</h3>
                                            <p>We are flexible and can arrange for a luggage transfer at a minimal cost.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accordion-faq-4-heading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-4" aria-expanded="false" aria-controls="accordion-faq-4">
                                            Scooter Rental FAQs
                                        </button>
                                    </h2>
                                    <div id="accordion-faq-4" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-4-heading" data-bs-parent="#accordion-faq">
                                        <div className="accordion-body text-black">
                                            <h3 className="text-secondary">Who can rent a scooter?</h3>
                                            <p>Anyone who proves his competency as rider by producing a valid driving licence issued at least 1 years prior to the hire date.</p>
                                            <h3 className="text-secondary">Is there a minimum rental period?</h3>
                                            <p>Yes.The minimum rental period is 3 days.</p>
                                            <h3 className="text-secondary">How can I make a booking?</h3>
                                            <p>You can book online through the booking engine, email, phone, Live chat or on Skype.Like &nbsp;car hire &nbsp;25% of the total rental price is needed to confirm your booking. A reservation voucher will be sent via email after the deposit payment with all details.</p>
                                            <h3 className="text-secondary">What type of Insurance cover OLA Mauritius provides?</h3>
                                            <p>We offer all comprehensive insurance cover on all our scooters and it is included in your rental.</p>
                                            <h3 className="text-secondary">Do I need to pay any Excess cover?</h3>
                                            <p>Yes. You need to make a deposit excess of 8000 MUR (Approx.200 EUR) at the time of delivery which is refundable if the scooter is returned in the same condition. This can be done either by cash or by preauthorisation from your credit card. Note that the excess amount from your credit card will not be debited.</p>
                                            <h3 className="text-secondary">Can the scooter be used by two people?</h3>
                                            <p>You can ride all our scooters with a passenger.&nbsp;However&nbsp; you will need an extra helmet, which we provide at no extra cost.</p>
                                            <h3 className="text-secondary">Do you provide delivery service for hotels?</h3>
                                            <p>Yes.We deliver scooters to your hotel for a minimum rental of 10 days and will have a surcharge of &nbsp;19 Euros for the transfer.</p>
                                            <h3 className="text-secondary">What about airport delivery?</h3>
                                            <p>For Airport and hotels in the south the delivery is free of charge.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accordion-faq-5-heading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-5" aria-expanded="false" aria-controls="accordion-faq-5">
                                            Chauffeur Service FAQ
                                        </button>
                                    </h2>
                                    <div id="accordion-faq-5" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-5-heading" data-bs-parent="#accordion-faq">
                                        <div className="accordion-body text-black">
                                            <h3 className="text-secondary">How is my reservation confirmed?</h3>
                                            <p>A detailed confirmation voucher will be sent to you. If you reserve by phone, vouchers are faxed to you immediately upon receipt of payment. If you reserve online, vouchers are emailed to you immediately upon receipt of payment. In either case, a copy is mailed the next business day.</p>
                                            <h3 className="text-secondary"><strong>How quickly can I arrange for transfer services?</strong></h3>
                                            <p>OLA Mauritius guarantees confirmation with a minimum of 24 hours advance notice, however we are able to accommodate most transfer reservation requests with as little as 3 hours advance notice. Services must be fully pre-paid, and credit card information will be kept on file for any extra charges (such as cellular phone services, etc.)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </section>
                </div>
                <div className="sidebar col-md-4">
                    <aside className="sidebar-widget">
                        <h4 className="widget-title">Benefits</h4>
                        <hr />
                        <div className="textwidget">
                            <div><i className="bi bi-check"></i> Free Additional Drivers</div>
                            <div><i className="bi bi-check"></i> Comprehensive Insurance</div>
                            <div><i className="bi bi-check"></i> 24/7 Breakdown Assistance</div>
                            <div><i className="bi bi-check"></i> Save level fuel policy</div>
                        </div>
                    </aside>
                    <aside className="sidebar-widgetm mt-4">
                        <h4 className="widget-title">Guaranteed Low Price</h4>
                        <hr />
                        <div className="textwidget">
                            <div className="pricing-option active">
                                <strong>Price starts at</strong>
                                <div className="price-container text-center">
                                    <span className="dollar text-secondary">€</span>
                                    <span className="price text-secondary">25</span>
                                    <span className="terms text-secondary">/day</span>
                                </div>
                                <img className="d-block m-auto" src="../../images/pages/homecar.webp" alt="car" width="213" height="104" />

                                <a href="/en" className="btn btn-primary fw-bold w-100 text-white my-4">Search for a Car</a>

                            </div></div>
                    </aside>

                </div>

            </div>
        </div>
    </div>);
}

export default Faq;