import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import lang from "../lang/lang.json";

function ConvertibleRental() {
	const params = useParams();

	return (<div className="ola-old-pages">
		<section className="internal-header">

			<div className="container">
				<div className="row v-align-children">
					<div className="col-md-8">
						<h1>
							Convertible Rental
						</h1>
					</div>

					<div className="col-md-4 d-none d-md-block">
						<ol className="breadcrumb float-end">
							<li>
								<a href={params.lang ? `/${params.lang}/` : `en/`}>
									{
										params.lang == "fr" ?
											"Accueil"
											:
											"Home"
									}
								</a>
							</li>
							<li className="current">
								Convertible Rental
							</li>
						</ol>
					</div>

				</div>
			</div>
		</section>

		<div className="container mt-2 mb-5">

			<div className="row">
				<div className="col-md-8">
					<section className="text-box">
						<article>
							<p>
								Experience Mauritius in a unique way with our convertible car rental service. Travel the island’s roads, from its coastal areas to its inland sights, in a high-quality convertible. Our vehicles offer comfort, safety, and a premium driving experience. Whether you’re here for business or leisure, our convertible rentals provide a reliable and sophisticated way to explore Mauritius. Book with us for a dependable and professional service.
							</p>
							<h3 className="text-secondary py-3">
								Your Convertible rental journey in Mauritius starts here
							</h3>
							<p>
								Mauritius beckons with its breathtaking landscapes and vibrant culture. With Ola Mauritius, experience this paradise from the vantage of a stylish convertible.
							</p>
							<ul>
								<li aria-level="1"><b>Unbeatable Deals:</b> Ola Mauritius is synonymous with value. Our competitive rates ensure that you enjoy the luxury of a convertible without the hefty price tag.</li>
								<li aria-level="1"><b>A Fleet of Elegance:</b> Our curated selection of convertibles is designed for those with a penchant for style. Every vehicle in our fleet promises a drive that’s as smooth as it is stylish.</li>
								<li aria-level="1"><b>Airport Pick-Up:</b> Begin your Mauritian journey without delay. Your convertible will be ready for you at the airport, ensuring a seamless transition from flight to drive.</li>
								<li aria-level="1"><b>Flexible Rental Periods:</b> Whether you’re here for a weekend getaway or an extended vacation, our rental plans can be tailored to fit your itinerary.</li>
								<li aria-level="1"><b>A Legacy of Trust:</b> Our commitment to excellence is echoed by our clientele. Dive into our testimonials and discover why many choose Ola Mauritius for their convertible car hire needs.</li>
							</ul>
							<p>
								For a driving experience that marries style with substance, Ola Mauritius is your premier choice. Embrace the open road with elegance and flair.
							</p>
							<h3 className="text-secondary py-3">
								Key Benefits of Convertible Hire in Mauritius
							</h3>

							<p>
								Opting for a convertible car hire in Mauritius presents not only a touch of luxury but also an incredibly cost effective option for travelling in style.
							</p>
							<p>
								While taxis offer convenience, their costs can quickly add-up, particularly over longer routes or during high-demand periods. For example, a single taxi ride from the airport to Grand Baie might set you back around £40.
							</p>
							<p>
								Our fleet of convertible feature rates as low as £35 – for the entire day!
							</p>
							<p>
								Our vehicles come equipped with modern comforts, including air conditioning and GPS navigation, ensuring a comfortable and guided journey.
							</p>
							<p>
								The true value of hiring a convertible shines when considering the freedom it offers.
							</p>
							<p>
								Travel on your terms, make spontaneous stops, and bypass the wait for taxis or public transport. The freedom to explore various locales, cover multiple destinations in one day, and set your own schedule can result in a huge saving, and an incredible holiday.
							</p>
							<p>
								With an Ola Mauritius convertible, you’re not just saving money; you’re investing in an unforgettable experience.
							</p>
							<h3 className="text-secondary py-3">
								Convertible Car Rental from Mauritius Airport
							</h3>
							<p>
								With our convertible car rental service, there’s no need for lengthy waits or cumbersome transfers. We ensure that your chosen convertible is ready for you right at the Sir Seewoosagur Ramgoolam International Airport.
							</p>
							<p>
								This direct pick-up service is designed with your convenience in mind:
							</p>
							<ul>
								<li aria-level="1"><b>Immediate Access:</b> Your convertible awaits you as soon as you exit the terminal, allowing you to transition smoothly from air travel to road exploration.</li>
								<li aria-level="1"><b>Hassle-Free Documentation:</b> Our streamlined process ensures that all necessary paperwork is prepared in advance, minimising the time you spend before driving off.</li>
								<li aria-level="1"><b>Personalised Service:</b> Our dedicated representatives will be on hand at the airport to assist with any queries, ensuring a seamless pick-up experience.</li>
								<li aria-level="1"><b>Cheapest car rental in Mauritius</b>: many of the deals we offer on convertibles make us one of the most cost-effective rental services in Mauritius.</li>
							</ul>
							<p>
								With Ola Mauritius, luxury car rental in Mauritius has never been easier.
							</p>
							<h3 className="text-secondary py-3">
								Frequently Asked Questions (FAQs)
							</h3>
							<h4 className="py-3">
								Do we need a car in Mauritius?
							</h4>
							<p>
								While Mauritius offers various transportation options, including taxis and buses, renting a car provides unparalleled convenience and flexibility. With a car, you can explore the island’s diverse landscapes, hidden gems, and local spots at your own pace.
							</p>
							<p>
								It’s especially beneficial for those who wish to visit multiple destinations in a day or avoid the constraints of public transport schedules.
							</p>
							<p>
								Additionally, for longer stays or trips to less frequented areas, a car can be more cost-effective than relying on taxis.
							</p>
							<h4 className="py-3">
								Is a convertible worth it in Mauritius?
							</h4>
							<p>
								Absolutely! Mauritius boasts a pleasant tropical climate, scenic coastal roads, and breathtaking landscapes, making it ideal for convertible drives.
							</p>
							<p>
								A convertible allows you to fully immerse in the island’s beauty, feel the ocean breeze, and enjoy unobstructed views.
							</p>
							<p>
								For those seeking a blend of adventure and luxury, a convertible enhances the Mauritian travel experience, making it a memorable and unique journey.
							</p>
							<p>
								Convertible car rental in Mauritius has never been easier!
							</p>
							<h4 className="py-3">
								Is insurance included in the car rental price?
							</h4>
							<p>
								All rentals at OLA Mauritius come along with an ”All Comprehensive Insurance Cover” which include CDW (Collision Damage Waiver ) and TP (Theft Protection) with a deductible or excess varying from 500 to 700 Euros depending on the car models or categories.
							</p>
							<p>
								You therefore limit your financial liability to the deductible or excess amount.
							</p>
							<p>
								However you’ll not be covered by our insurance in cases of drunken driving or gross negligence.
							</p>
							<p>
								To be noted that we will block the excess amount on your credit card as a deposit. If you return the vehicle undamaged, the deposit will be released immediately.
							</p>
							<h4 className="py-3">
								Am I allowed to modify my car rental booking?
							</h4>
							<p>
								At Ola Mauritius, we understand that holidays can change.
							</p>
							<p>
								You can contact us at any time of your trip and we will do our best to accommodate any change of plans.
							</p>
							<p>
								However, we may not always be able to modify your car rental booking, so it is important to plan ahead.
							</p>
							<h4 className="py-3">
								Do you offer airport pick-up and drop-off services?
							</h4>
							<p>
								At OLA Mauritius, we prioritise your convenience and flexibility. No matter the time, whether it’s dawn or dusk, we’re prepared to pick you up from any airport and transport you to your chosen destination across the island.
							</p>
							<p>
								Our commitment to personalised service ensures that you have unparalleled control over your travel itinerary.
							</p>
						</article>
					</section>
				</div>

				<div className="sidebar col-md-4">
					<aside className="sidebar-widget">
						<h4 className="widget-title">Benefits</h4>
						<hr />
						<div className="textwidget">
							<div><i className="bi bi-check"></i>A-Class Fleet</div>
							<div><i className="bi bi-check"></i>Free Additional Driver</div>
							<div><i className="bi bi-check"></i>Free Child Seats</div>
							<div><i className="bi bi-check"></i>All Comprehensive Insurance Cover</div>
							<div><i className="bi bi-check"></i>24 hours Roadside Assistance</div>
							<div><i className="bi bi-check"></i>Free Delivery &amp; Pick up</div>
						</div>
					</aside>
					<aside className="sidebar-widget">
						<h4 className="widget-title mt-4">Guaranteed Low Price</h4>
						<hr />
						<div className="textwidget">
							<div className="pricing-option active">
								<strong>Price starts at</strong>
								<div className="price-container text-center">
									<span className="dollar text-secondary">€</span>
									<span className="price text-secondary">25</span>
									<span className="terms text-secondary">/day</span>
								</div>
								<img className="d-block m-auto" src="../../images/pages/homecar.webp" alt="car" width="213" height="104" />

								<a href="/en" className="btn btn-primary fw-bold w-100 text-white my-4">Search for a Car</a>

							</div></div>
					</aside>

				</div>

			</div>

		</div>
	</div>);
}

export default ConvertibleRental;