import { useEffect } from "react";
import { RangePicker } from "react-trip-date";

const date = new Date()

const backIcon =<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 13L14 17L18 21" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>


const monthOrder = () => {
    const  month= ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const currentMonth = date.getMonth()
    const pastMonths = month.slice(0,currentMonth).map(month => `${month} ${date.getFullYear()+1}`)
    const nextMonths = month.slice(currentMonth).map(month => `${month} ${date.getFullYear()}`)
    const newMonthOrder = nextMonths.concat(pastMonths)
    return newMonthOrder
}

const weekDays = ["MO", 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']

const customDaysComponent = ({day}) => {
    let customDay = day.split('-')[2]
    customDay = customDay[0] === '0' ? customDay[1] : customDay
    return customDay
}

const months = monthOrder()



const CalendarModal = ({onClose, setSelectedDays, dates, theme}) => {

    useEffect(()=>{
        const app = document.querySelector('.App')
        app.classList.add('d-none')
        return () => app.classList.remove('d-none')
    },[])

    const disableBtn = () => {
        if(dates.to && dates.from){
            return !(Math.floor((Date.parse(dates.to) - Date.parse(dates.from)) / 86400000) >= 3)
        }
        return true
    }
    
    const onClearHandler = () => {
        setSelectedDays({
            from: '',
            to: ''
        })
    }

    const onCloseHandler = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
        onClose()
    }

    console.log(`${date.getFullYear}0${date.getMonth+1}`);

    return (
        <div className="calendar-modal ">
            <div className="calendar-modal__header">
                <div className="calendar-modal__header--top">
                    <button className="calendar-modal__btn--back" onClick={onCloseHandler}>
                        {backIcon}
                    </button>
                    <span>Trip dates</span>
                    <button onClick={onClearHandler} className="calendar-modal__btn--clear"><i className="bi bi-x-lg"></i>Clear</button>
                </div>
                <div className="tp-calendar-week-titles" >
                    {weekDays.map((day, i) => <p key={i}>{day}</p>)}
                </div>
            </div>
            <div className="calendar__container">
                <RangePicker
                    numberOfMonths={12}
                    initialMonthAndYear={`${date.getFullYear()}0${date.getMonth()+1}`}
                    disabledBeforeToday
                    autoResponsive={false}
                    theme={theme}
                    disabledBeforToday={true}
                    handleChange={(res) => console.log("ress>>>", res)}
                    onChange={(dates) => setSelectedDays(dates)}
                    selectedDays={dates}
                    components={{
                        days: customDaysComponent,
                    }}
                />
            </div>
            <div className='calendar-modal__btn'>
                    <button className="btn btn-primary w-100" onClick={onCloseHandler} disabled={disableBtn()}>Continue</button>
            </div>        
            <div className="month__labels">
                {months.map(month => <div key={month}><span>{month}</span></div>)}
            </div>
        </div>
    )
}

export default CalendarModal