import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

function WhyOla() {
    const params = useParams();

    return (<div className="ola-old-pages">
        <section className="internal-header">

            <div className="container">
                <div className="row v-align-children">
                    <div className="col-md-8 bold">
                        <h1>
                            Why OLA
                        </h1>
                    </div>

                    <div className="col-md-4 d-none d-md-block">
                        <ol className="breadcrumb float-end semi-bold">
                            <li>
                                <a href={params.lang ? `/${params.lang}/` : `en/`}>
                                    {
                                        params.lang == "fr" ?
                                            "Accueil"
                                            :
                                            "Home"
                                    }
                                </a>
                            </li>
                            <li className="current">Why OLA</li>
                        </ol>
                    </div>

                </div>
            </div>
        </section>

        <div className="container mt-2 mb-5">
            <div className="row">

                <div className="col-md-8">
                    <section className="text-box">
                        <article>
                            <h2 className="text-secondary">Our Benefits</h2>
                            <p>
                                <a href="/en/testimonials/">
                                    <img className="float-end" src="../../images/pages/why-ola-meet.webp" alt="meet" width="387" height="327" />
                                </a>
                                We don’t believe in just renting you a car or a scooter; we care about all the supplemental that comes along with it. All rentals from us are packed with the following benefits:
                            </p>
                            <ul>
                                <li><a className="text-muted" href="/en/benefits/#fleet">A-Class Fleet</a></li>
                                <li><a className="text-muted" href="/en/benefits/#gps">GPS</a></li>
                                <li><a className="text-muted" href="/en/benefits/#driver">Free Additional Driver</a></li>
                                <li><a className="text-muted" href="/en/benefits/#child">&nbsp;Child Seats</a></li>
                                <li><a className="text-muted" href="/en/benefits/#insurance">All Comprehensive Insurance Cover</a></li>
                                <li><a className="text-muted" href="/en/benefits/#road">24 hours Roadside Assistance</a></li>
                                <li><a className="text-muted" href="/en/benefits/#delivery">Free Delivery &amp; Pick up</a></li>
                            </ul>
                            <h2 className="text-secondary mt-4">Our Promise</h2>
                            <p>Because we are a customer centric car hire agency we commit ourselves to providing you with the most exceptional and complete service. &nbsp;Far from being a mere concept we mandate ourselves to honor our promises and make your car rental experience in Mauritius something to cherish forever.</p>
                            <p>
                                <img className="aligncenter size-full wp-image-540" src="../../images/pages/why-ola-olapromisebadges.webp" alt="olapromisebadges" width="673" height="155" />
                            </p>
                        </article>
                    </section>
                </div>

            </div>
        </div>
    </div>);
}

export default WhyOla;