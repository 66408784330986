import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

function Privacy() {
    const params = useParams();

    return (<div className="ola-old-pages">
        <section className="internal-header">

            <div className="container">
                <div className="row v-align-children">
                    <div className="col-md-8">
                        <h1>
                            {
                                params.lang == "fr" ?
                                    "Mentions Légales"
                                    :
                                    "Privacy Policy"
                            }
                        </h1>
                    </div>

                    <div className="col-md-4 d-none d-md-block">
                        {
                            params.lang == "fr" ?
                                <ol className="breadcrumb float-end">
                                    <li><a href="/fr/">Accueil</a></li><li className="current">Mentions Légales</li>
                                </ol>
                                :
                                <ol className="breadcrumb float-end">
                                    <li><a href="/en/">Home</a></li><li className="current">Privacy Policy</li>
                                </ol>
                        }

                    </div>

                </div>
            </div>
        </section>

        <div className="container mt-2 mb-5">
            {
                params.lang == "fr" ?
                    <div className="row">
                        <div className="col-md-8">
                            <p><strong>Quelles sont les informations que nous recueillons?</strong></p>
                            <p>Nous collectons des informations sur vous lorsque vous passez une commande, vous abonnez à notre newsletter, répondez à un sondage ou remplissez un formulaire.</p>
                            <p>Lors de la commande ou en vous inscrivant sur notre site, dépendant des cas, vous pouvez être invité à entrer votre : nom, adresse e-mail, adresse postale ou numéro de téléphone.</p>
                            <p><strong>Comment utilisons-nous les informations que vous nous procurez?</strong></p>
                            <p>Toutes les informations que nous recueillons auprès de vous peuvent être utilisées dans l’une des façons suivantes:</p>
                            <p>; Pour personnaliser votre expérience</p>
                            <p>(Vos informations nous aident à mieux répondre à vos besoins individuels)</p>
                            <p>; Afin d’améliorer notre site web</p>
                            <p>(Nous nous efforçons d’améliorer continuellement nos offres basées sur les informations et les commentaires que nous recevons de vous)</p>
                            <p>; Pour améliorer le service clientèle</p>
                            <p>(Vos informations nous aident à répondre plus efficacement à vos demandes et besoins concernant notre service client)</p>
                            <p>; Pour traiter les transactions</p>
                            <p>Vos informations, qu’elles soient publiques ou privées, ne seront pas vendues, échangées, transférées, ou donnés à une autre société pour quelque raison que ce soit, sans votre consentement, sauf dans le but de livrer le produit acheté ou le service demandé.</p>
                            <p>; Pour les besoins d’un&nbsp; concours, une promotion, un sondage ou autre</p>
                            <p>; Pour envoyer des courriels périodiques</p>
                            <p>L’adresse email que vous fournissez pour le traitement de la commande peut être utilisée pour vous envoyer des informations et mises à jour relatives à votre commande, en plus de recevoir occasionnellement des nouvelles de l’entreprise, les mises à jour, les informations liées aux produits ou services, etc.</p>
                            <p><strong>Comment protégeons-nous vos informations?</strong></p>
                            <p>Nous mettons en œuvre une variété de mesures de sécurité pour sécuriser vos informations personnelles lorsque vous passez une commande ou entrez, soumettez ou accédez à vos informations personnelles.</p>
                            <p>Nous vous proposons l’utilisation d’un serveur sécurisé. Toutes les informations sensibles / informations de crédit sont transmises via le protocole sécurisé SSL (Secure Socket Layer), puis cryptées dans la base de données de la passerelle de paiement de nos fournisseurs et n’est accessible qu’aux personnes autorisées avec des droits d’accès spéciaux à ces systèmes, et ces personnes sont tenus de garder l’information confidentielle.</p>
                            <p>Après une opération, vos informations personnelles (cartes de crédit, numéros de sécurité sociale, finances, etc.) ne seront pas stockées sur nos serveurs.</p>
                            <p><strong>Utilisons-nous des cookies?</strong></p>
                            <p>Non, nous n’utilisons pas de cookies.</p>
                            <p><strong>Est-ce que nous divulguons vos informations à des tiers?</strong></p>
                            <p>Nous ne vendons pas, n’échangeons pas et ne transférerons pas à des tiers les renseignements personnellement identifiables vous concernant. Ceci n’inclut pas les tiers de confiance qui nous aident à exploiter notre site Web, la conduite de nos affaires, ou vous servir, tant que ces parties conviennent de garder cette information confidentielle. Nous pouvons également divulguer vos informations lorsque nous croyons cela nécessaire pour nous conformer à la loi, appliquer les politiques du site, ou la protection de nos droits, notre propriété, notre sécurité et de ceux d’autrui. Toutefois, les informations personnelles non identifiables de nos visiteurs peuvent être fournies à d’autres parties pour le marketing, la publicité, ou d’autres usages.</p>
                            <p><strong>Confidentialité en ligne uniquement</strong></p>
                            <p>Cette politique de confidentialité en ligne s’applique uniquement aux informations collectées sur notre site et non aux informations recueillies hors ligne.</p>
                            <p><strong>Termes et Conditions</strong></p>
                            <p>Veuillez également visiter notre section Termes et Conditions instituant l’utilisation, les avertissements et limitations de responsabilité régissant l’utilisation de notre site Web à
                                <a className="text-secondary" href="/fr/conditions-generale/"> http://www.olamauritius.com/fr/conditions-generale/</a></p>
                            <p><strong>Votre consentement</strong></p>
                            <p>En utilisant notre site, vous consentez à la politique de confidentialité de notre site Web.</p>
                            <p><strong>Changements par rapport à notre politique de confidentialité</strong></p>
                            <p>Si nous décidons de modifier notre politique de confidentialité, nous afficherons ces modifications sur cette page, et/ou vous enverrons un courriel vous avisant de tout changement.</p>
                            <p><strong>Nous contacter</strong></p>
                            <p>Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter en utilisant les informations ci-dessous.</p>
                            <p>OLA Mauritius<br />
                                94 A Daurades Street<br />
                                Grand Port, Blue Bay<br />
                                Mauritius<br />
                                <i className="bi bi-envelope"></i>
                                <a className="px-2 text-secondary" href="mailto:info@olamauritius.com">info@olamauritius.com</a>
                                <br />
                                <i className="bi bi-phone"></i>
                                <a className="px-2 text-secondary" href="tel:+2307900575">+2307900575</a>
                            </p>
                        </div>

                        <div className="sidebar col-md-4">
                            <aside className="sidebar-widget">
                                <h4 className="widget-title">Vos Plus</h4>
                                <hr />
                                <div className="textwidget">
                                    <ul>
                                        <li>Des voitures haut de gamme</li>
                                        <li>Un chauffeur supplémentaire gratuit</li>
                                        <li>Une assurance tous risques</li>
                                        <li>Une assistance routière de 24 heures</li>
                                        <li>Livraison et récupération gratuite</li>
                                    </ul>
                                </div>
                            </aside>
                            <aside className="sidebar-widget">
                                <h4 className="widget-title">Contact</h4>
                                <hr />
                                <div className="textwidget"><p>Vous n'arrivez pas à trouver la réponse à une question? Contactez-nous et l'un des membres de notre équipe se fera un plaisir de vous aider</p>
                                    <p>
                                        <i className="bi bi-phone"></i>
                                        <a className="px-2 text-secondary" href="tel:+230 5790 0575">+230 5790 0575</a>
                                        <br />
                                        <i className="bi bi-envelope"></i>
                                        <a className="px-2 text-secondary" href="mailto:info@olamauritius.com">
                                            info@olamauritius.com
                                        </a>
                                    </p>
                                </div>
                            </aside>
                        </div>

                    </div>
                    :
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <p><strong>What information do we collect?</strong></p>
                            <p>We collect information from you when you place an order, subscribe to our newsletter, respond to a survey or fill out a form.</p>
                            <p>When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address or phone number.</p>
                            <p><strong>What do we use your information for?</strong></p>
                            <p>Any of the information we collect from you may be used in one of the following ways:</p>
                            <p>; To personalize your experience<br />
                                (your information helps us to better respond to your individual needs)</p>
                            <p>; To improve our website<br />
                                (we continually strive to improve our website offerings based on the information and feedback we receive from you)</p>
                            <p>; To improve customer service<br />
                                (your information helps us to more effectively respond to your customer service requests and support needs)</p>
                            <p>; To process transactions</p>
                            <blockquote><p>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.</p></blockquote>
                            <p>; To administer a contest, promotion, survey or other site feature</p>
                            <p>; To send periodic emails</p>
                            <blockquote>
                                <p>
                                    The email address you provide for order processing, may be used to send you information and updates pertaining to your order, in addition to receiving occasional company news, updates, related product or service information, etc.
                                </p>
                            </blockquote>
                            <p><strong>How do we protect your information?</strong></p>
                            <p>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.</p>
                            <p>We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to?keep the information confidential.</p>
                            <p>After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.</p>
                            <p><strong>Do we use cookies?</strong></p>
                            <p>We do not use cookies.</p>
                            <p><strong>Do we disclose any information to outside parties?</strong></p>
                            <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
                            <p><strong>Online Privacy Policy Only</strong></p>
                            <p>This online privacy policy applies only to information collected through our website and not to information collected offline.</p>
                            <p><strong>Terms and Conditions</strong></p>
                            <p>Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at
                                <a className="text-secondary" href="/en/terms-and-conditions/"> http://www.olamauritius.com/en/terms-and-conditions/</a>
                            </p>
                            <p><strong>Your Consent</strong></p>
                            <p>By using our site, you consent to our website’s privacy policy.</p>
                            <p><strong>Changes to our Privacy Policy</strong></p>
                            <p>If we decide to change our privacy policy, we will post those changes on this page, and/or send an email notifying you of any changes.</p>
                            <p><strong>Contacting Us</strong></p>
                            <p>If there are any questions regarding this privacy policy you may contact us using the information below.</p>
                            <p><a className="text-secondary" href="/">http://www.olamauritius.com/</a><br />
                                94 A Daurades Street<br />
                                Grand Port, Blue Bay<br />
                                Mauritius<br />
                                <i className="bi bi-envelope"></i>
                                <a className="px-2 text-secondary" href="mailto:info@olamauritius.com">info@olamauritius.com</a>
                                <br />
                                <i className="bi bi-phone"></i>
                                <a className="px-2 text-secondary" href="tel:+2307900575">+2307900575</a>
                            </p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
            }
        </div>
    </div>);
}

export default Privacy;